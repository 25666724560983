import React, { useEffect, useState } from "react";
import "./App.css";
import "@fontsource/rubik";

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Layout from "./component/Layout";
import Login from "./component/login";
import Dashboard from "./component/dashboard";
import Brochure from "./component/brochureView";
import AddProjects from "./component/addProjects";
import UnitDetails from "./component/unitDetails";
import LoginDesign from "./component/stylelogin";
import ProjectView from "./component/projectView";
import ProjectEdit from "./component/projectEdit";
import HomeFeedback from "./component/homeFeedback";
import SignUp from "./component/signUp";
import firebase from "firebase";
import { AuthProvider } from "./component/auth";
import { RequireAuth } from "./component/RequireAuth";
import SeoPage from "./component/seoPage";
import TermsCondtion from "./component/terms-cond";

// import Register from './register';

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [time, setTime] = useState("");
  useEffect(() => {
    setTime("4553");
    const user = localStorage.getItem("login");
    console.log("usersss", user);
    setCurrentUser(user);
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <Layout />
        <Routes>
          <Route path="sign-up" element={<SignUp />} />
          <Route path="login" element={<Login />} />
          <Route path="terms-conditions" element={<TermsCondtion />}></Route>
          <Route
            index
            path="*"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="add-products"
            element={
              <RequireAuth>
                <AddProjects />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="products"
            element={
              <RequireAuth>
                <ProjectView />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/account"
            element={
              <RequireAuth>
                <Brochure />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="order-details"
            element={
              <RequireAuth>
                <UnitDetails />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="edit_projects"
            element={
              <RequireAuth>
                <ProjectEdit />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="customer-feedback"
            element={
              <RequireAuth>
                <HomeFeedback />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="seo-category/:seopage"
            element={
              <RequireAuth>
                <SeoPage />
              </RequireAuth>
            }
          ></Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
