import {React,useState, useEffect} from "react";
import db from "./firebase";
import {useNavigate} from "react-router-dom";
import './css/styles.css';
import phone from "./images/phone.svg";
import trash from "./images/trash.svg";
import { Button } from "react-bootstrap";
import deletepopup from "../images/deletepopup.svg";
import { Modal } from "react-bootstrap";
import norecord from "./images/no record found.svg";
import { Helmet } from "react-helmet";


function UnitDetails() {

  const [info, setInfo] = useState([]);
  const [fullinfo, setFullinfo] = useState([]);
  const [buildername , setBuilderName] = useState([]);
  const [yearArray , setYearArray] = useState([]);


  useEffect(() => {
    Fetchdata()

  }, [])

  const Fetchdata = ()=>{
    current_date();
  db.collection("UnitDetailSubmit").orderBy("timestamp","desc").get().then((querySnapshot) => {

    // Loop get the data fetching here
    querySnapshot.forEach(element => {
      var data = element.data();


      data.docId = element.id;
      setInfo(arr => [...arr, data]);
      var builder_name_assign = data.builder_details.builder_name
      setBuilderName(arr => [...arr, builder_name_assign])
      setFullinfo(arr=>[...arr,data])

    });
  })
}

const [date, setDate] = useState('');
const [month, setMonth] = useState('');
const [year, setYear] = useState('');

const handleChangeDate = (event) => {
  setDate(event.target.value);
};
const handleChangeName = (event) => {
  setMonth(event.target.value);

};
const handleChangeArea = (event) => {
  setYear(event.target.value);

};
function filter_search(){

  if (date === "" && year === "") {
    db.collection("UnitDetailSubmit")
    .where("month","==",month)
    .get()
    .then((querySnapshot) => {
        
      let SearchLists =[]
        // Loop get the data fetching here
        querySnapshot.forEach(element => {
          var data = element.data();
         
          data.docId = element.id;
          SearchLists.push(data)
       
        });   
        setInfo(SearchLists)
    })  
  } 
  else if (month === "" && year === "") {
    var date_int = parseInt(date)
    db.collection("UnitDetailSubmit")
    .where("date","==",date_int)
    .get()
    .then((querySnapshot) => {
        
      let SearchLists=[]
        // Loop get the data fetching here
        querySnapshot.forEach(element => {
          var data = element.data();
                
          data.docId = element.id;
          SearchLists.push(data)
          
        });   
        setInfo(SearchLists)
    })  
  }
  else if (date === "" && month === "") {
    var year_int = parseInt(year)
    db.collection("UnitDetailSubmit")
    .where("year","==",year_int)
    .get()
    .then((querySnapshot) => {
        let SearchLists =[]
        // Loop get the data fetching here
        querySnapshot.forEach(element => {
          var data = element.data();
              
          data.docId = element.id;
          SearchLists.push(data)
        });   
        setInfo(SearchLists)
    })  
  }  
 else if (date === ""){ 
  db.collection("UnitDetailSubmit")
    .where("month","==",month)
    .where("year","==",parseInt(year))
    .get()
    .then((querySnapshot) => {
        let SearchLists =[]
        // Loop get the data fetching here
        querySnapshot.forEach(element => {
          var data = element.data();
         
        
          data.docId = element.id;
          SearchLists.push(data)
        });   
        setInfo(SearchLists)
    })  
  } 
  else if (month === ""){
    db.collection("UnitDetailSubmit")
      .where("date","==",parseInt(date))
      .where("year","==",parseInt(year))
      .get()
      .then((querySnapshot) => {
          let SearchLists =[]
          // Loop get the data fetching here
          querySnapshot.forEach(element => {
            var data = element.data();
           
          
            data.docId = element.id;
            SearchLists.push(data)
           
          });   
          setInfo(SearchLists)
      })  
    } 
    else if (year === ""){
      db.collection("UnitDetailSubmit")
      .where("date","==",parseInt(date))
      .where("month","==",month)
      .get()
      .then((querySnapshot) => {
          let SearchLists =[]
          // Loop get the data fetching here
          querySnapshot.forEach(element => {
            var data = element.data();          
          
            data.docId = element.id;
            SearchLists.push(data)
         
          });   
          setInfo(SearchLists)
      })  
      }     

  else {
    db.collection("UnitDetailSubmit")
      .where("date", "==", parseInt(date))
      .where("month", "==", month)
      .where("year", "==", parseInt(year))
      .get()
      .then((querySnapshot) => {
        let SearchLists=[]
        querySnapshot.forEach((element) => {
          var data = element.data();

          data.docId = element.id;
          SearchLists.push(data)
        });
        setInfo(SearchLists)
      });
  } 
}


const groupBy = (key) => (array) =>
array.reduce((objectsByKeyValue, obj) => {
  const value = obj[key];
  objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
  return objectsByKeyValue;
}, {});

const groupBycity = groupBy('city');
var split_group_by_builder_city = groupBycity(fullinfo)

const groupByarea = groupBy('builder_area');
var split_group_by_builder_area = groupByarea(fullinfo)

const groupBybuildername = groupBy('builder_area');
var split_group_by_builder_name = groupBy(buildername)
var uniquebuildername = [...new Set(buildername)];

// var year_array = [];
function current_date(){
    var year_get = new Date().getFullYear();
    var previous_year = year_get - 5;
    for (let index = previous_year; index <= year_get; index++) {
        //  element = index;
        setYearArray(arr=>[...arr,index])
    }
   

 }
return (
  <div>
    <Helmet>
      <title>Sailors Orders History</title>
    </Helmet>
  <div class="container mt-5">
  <div class="row">
    <div class="col-md-12">
    <h4>Order Details</h4>
    </div>
  </div>
</div>
  <div class="pt-4 pb-5">
         <div className="container-fluid">
        <div className="container">
            <div className="row filter-ui">
              <div className="col-md-3">
                <p className="mb-2">Select Date</p>
                <select onChange={handleChangeDate}>
                    <option value="">Select Date</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                        <option>13</option>
                        <option>14</option>
                        <option>15</option>
                        <option>16</option>
                        <option>17</option>
                        <option>18</option>
                        <option>19</option>
                        <option>20</option>
                        <option>21</option>
                        <option>22</option>
                        <option>23</option>
                        <option>24</option>
                        <option>25</option>
                        <option>26</option>
                        <option>27</option>
                        <option>28</option>
                        <option>29</option>
                        <option>30</option>
                        <option>31</option>
                </select>
              </div>
              <div className="col-md-3">
                <p className="mb-2">Select Month</p>
                <select onChange={handleChangeName}>
                    <option value="">Select Month</option>
                      <option>January</option>
                      <option>February</option>
                      <option>March</option>
                      <option>April</option>
                      <option>May</option>
                      <option>June</option>
                      <option>July</option>
                      <option>August</option>
                      <option>September</option>
                      <option>October</option>
                      <option>November</option>
                      <option>December</option>  
                  </select>
              </div>
              <div className="col-md-3">
                <p className="mb-2">Select year</p>
                <select onChange={handleChangeArea}>
                    <option value="">Select year</option>
                    {yearArray.map((element) => (
                    <option>{element}</option>          
                  ))}
                  </select>
              </div>
              <div className="col-md-3">
              <button onClick={() => filter_search()} type="Search" className="search-filter-btn">Search</button>
              </div>
            </div>
        </div>
      </div>

    {info.map((data, querySnapshot) => (
      <Homepage
        email={data.email}
        name={data.house_type}
        mobile_number={data.mobile_number}
        intrested={data.feedback}
        wanted_name = {data.name}
        date_get = {data.date}
        year_get = {data.year}
        month_get = {data.month}
        project_name = {data.project_name}
        builder_name={data.builder_details.builder_name}
        doc_id={data.docId}
        property_address = {data.property_address}
      />
    ))}
    {/* {<footer/>} */}
    <div className="row text-center">
      <div>
        {info.length === 0 ? <img src={norecord} className="norecord" /> : null}
      </div>
    </div>
  </div>
  </div>
);
}
const Homepage = ({ email,
  name,
  mobile_number,
  intrested,
  builder_name,
  doc_id,
  wanted_name,
  date_get,
  month_get,
  year_get,
  project_name,
  property_address

}) => {
  const navigate = useNavigate()

  const [modalShow2, setModalShow2] = useState(false);

  function refreshPage() {
    window.location.reload(false);
  }

async function delete_func(doc_id_get){
  // alert(doc_id_get);
  // await deleteDoc(doc(db, "User_feedback", doc_id_get));
  const res = await db.collection('UnitDetailSubmit').doc(doc_id_get).delete();
  // Fetchdata()
  // alert("delete successfully")
  setModalShow2(false)
  // navigate('/dashboard')
  refreshPage()
}

function edit_function(value){
  navigate({
    pathname: '/edit_projects',
    search: value,

    })
  // alert(value);
}
// popup delete start
function MyVerticallyCenteredModal2(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      
      <Modal.Body class="text-center p-5">
        <img src={deletepopup} style={{ width: "65px", height: "65px", }} />
        <h4 class="text-center mt-4">{builder_name}</h4>
        <p class="text-center">
        Are you sure, you want to delete this project
        </p>
       <Button onClick={props.onHide} className="btn-popup-cancel mt-3">Cancel</Button>
       <Button onClick={()=>delete_func(doc_id)} className="btn-popup-delete mt-3">Delete</Button>
      </Modal.Body>
    </Modal>
  );
}


return(
  // {course.map((value) => (

<div class="container">
<div class="card mt-5">
    <div class="card-header">
        <div class="row">
            <div class="col-md-6"><h6 class="m-0">{wanted_name}<span class="color-grey"><span style={{marginLeft: "10px",}}>&#60;</span>{email}<span>&#62;</span></span></h6></div>
            <div class="col-md-6">
              <div class="d-flex justify-content-end">
                <p class="contact-no d-flex align-items-center"><img src={phone} style={{width:"16px" ,height:"16px"}} /><span class="pl-2" style={{marginLeft: "7px",}}>{mobile_number}</span></p>
                {/* <a href="##" class="btn-edit"><img onClick={()=>edit_function(doc_id)} src={edit} style={{width:"20px", height:"20px"}} /></a> */}
                {/* <a href="##" class="btn-delete"><img  onClick={()=>delete_func(doc_id)} src={trash} style={{width:"20px", height:"20px"}} /></a>
                 */}

                               <>
      <Button className="btn-delete" variant="primary" onClick={() => setModalShow2(true)}><img  src={trash} style={{width:"20px", height:"20px"}} />
      </Button>

      <MyVerticallyCenteredModal2
        show={modalShow2}
        onHide={() => setModalShow2(false)}
      />
    </>
                 
              </div>
            </div>
        </div>
    </div>
    <div class="card-body">
      <div class="row">
          <div class="col-md-10">
          <h5 class="card-title">{project_name}</h5>
      {/* <p class="card-text">Project Name: {builder_name}</p> */}
      <p class="card-text m-0">Builder Name: {builder_name}</p>
      <p class="card-text m-0">Address: {property_address}</p>
          </div>
          <div class="col-md-2">
          <p class="card-text text-end m-0">{date_get}/{month_get}/{year_get}</p>
      {/* <p class="card-text">{intrested}</p> */}
          </div>
      </div>
      
      
    </div>
</div>
</div>
  // ))}
)
}
export default UnitDetails;
