// import section
// In this page projects count show and users feedback show
import { React, useState, useEffect } from "react";
import db from "./firebase";
import { useLocation, useNavigate } from "react-router-dom";
import "./css/styles.css";
import phone from "./images/phone.svg";
import trash from "./images/trash.svg";
import { Button } from "react-bootstrap";
import deletepopup from "../images/deletepopup.svg";
import { Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
import norecord from "./images/no record found.svg";

// Main function start
// type DashboardProps = {
//   title: string;
// };

const Dashboard = () => {
  const [info, setInfo] = useState([]);
  const [countProduct, setCountProduct] = useState(0);
  const [countPlot, setCountPlot] = useState(0);
  const [countApartment, setCountApartment] = useState(0);
  const [countVila, setCountVila] = useState(0);

  const TITLE = "cgc-admin";
  const location = useLocation();
  var loginEmailGet = localStorage.getItem("email");
  useEffect(() => {
    Fetchdata();
  }, [loginEmailGet]);
  console.log("global variable", window.name);

  const Fetchdata = () => {
    db.collection("User_feedback")
      .orderBy("timestamp", "desc")
      .get()
      .then((querySnapshot) => {
        // Loop get the data fetching here
        // var count_product = querySnapshot.size;
        querySnapshot.forEach((element) => {
          var data = element.data();

          data.docId = element.id;
          setInfo((arr) => [...arr, data]);
        });
      });
    db.collection("Product_Details")
      .where(
        "vendor_details.contact_details",
        "==",
        localStorage.getItem("login_mob_no")
      )
      .get()
      .then(function (querySnapshot) {
        // querySnapshot.forEach(function(doc) {
        setCountProduct(querySnapshot.size);
        console.log(querySnapshot.size);
        // });
      });
    db.collection("Product_Details")
      .where("vendor_email", "==", loginEmailGet)
      .get()
      .then(function (querySnapshot) {
        // querySnapshot.forEach(function(doc) {
        console.log(querySnapshot.size);
        // });
      });
    db.collection("Product_Details")
      .where("vendor_email", "==", loginEmailGet)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {});
      });
    db.collection("Product_Details")
      .where("vendor_email", "==", loginEmailGet)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {});
      });
  };

  return (
    <div class="pb-5">
      {/* <center>
      
    </center> */}
      <Helmet>
        <title>{"Sailors choice Dashboard"}</title>
      </Helmet>
      <div className="container mt-5">
        <div className="row">
          <div class="col-md-3">
            <div class="card">
              <div class="card-header">
                <h6 class="m-0">Product count</h6>
              </div>
              <div class="card-body">
                <h1>{countProduct}</h1>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-header">
                <h6 class="m-0">Out of stock count</h6>
              </div>
              <div class="card-body">
                <h1>{countApartment}</h1>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-header">
                <h6 class="m-0">Delivery count</h6>
              </div>
              <div class="card-body">
                <h1>{countPlot}</h1>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-header">
                <h6 class="m-0">Cancel Orders Count</h6>
              </div>
              <div class="card-body">
                <h1>{countVila}</h1>
              </div>
            </div>
          </div>
          {/* <div class="col-md-3">{countApartment}</div>
        <div class="col-md-3">{countPlot}</div>
        <div class="col-md-3">{countVila}</div> */}
        </div>
      </div>
      <div class="container mt-5 mb-4">
        <div class="row">
          <div class="col-md-12">
            <h4>Today Orders</h4>
          </div>
        </div>
      </div>

      {info.map((data, querySnapshot) => (
        <Homepage
          email={data.email}
          name={data.name}
          mobile_number={data.mobile_number}
          intrested={data.feedback}
          project_name={data.project_name}
          doc_id={data.docId}
          builder_name={data.builder_details.builder_name}
          date_get={data.date}
          month_get={data.month}
          year_get={data.year}
          address_get={data.Address}
        />
      ))}
      {/* {<footer/>} */}
      <div className="row text-center">
        <div>
          {info.length === 0 ? (
            <img src={norecord} className="norecord" />
          ) : null}
        </div>
      </div>
    </div>
  );
};
// Main function end

// function recall section
const Homepage = ({
  email,
  name,
  mobile_number,
  intrested,
  project_name,
  doc_id,
  builder_name,
  month_get,
  year_get,
  date_get,
  address_get,
}) => {
  const [modalShow2, setModalShow2] = useState(false);

  const navigate = useNavigate();

  function refreshPage() {
    window.location.reload(false);
  }

  async function delete_func(doc_id_get) {
    await db.collection("User_feedback").doc(doc_id_get).delete();
    setModalShow2(false);
    navigate("/dashboard");
    refreshPage();
  }

  // popup delete start
  function MyVerticallyCenteredModal2(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body class="text-center p-5">
          <img src={deletepopup} style={{ width: "65px", height: "65px" }} />
          <h4 class="text-center mt-4">{project_name}</h4>
          <p class="text-center">
            Are you sure, you want to delete this project
          </p>
          <Button onClick={props.onHide} className="btn-popup-cancel mt-3">
            Cancel
          </Button>
          <Button
            onClick={() => delete_func(doc_id)}
            className="btn-popup-delete mt-3"
          >
            Delete
          </Button>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    // {course.map((value) => (

    <div class="container">
      <div class="card mb-5">
        <div class="card-header">
          <div class="row">
            <div class="col-md-6">
              <h6 class="m-0">
                {name}
                <span class="color-grey">
                  <span style={{ marginLeft: "10px" }}>&#60;</span>
                  {email}
                  <span>&#62;</span>
                </span>
              </h6>
            </div>
            <div class="col-md-6">
              <div class="d-flex justify-content-end">
                <p class="contact-no">
                  <img src={phone} style={{ width: "16px", height: "16px" }} />
                  <span class="pl-2" style={{ marginLeft: "10px" }}>
                    {mobile_number}
                  </span>
                </p>
                <>
                  <Button
                    className="btn-delete"
                    variant="primary"
                    onClick={() => setModalShow2(true)}
                  >
                    <img
                      src={trash}
                      style={{ width: "20px", height: "20px" }}
                    />
                  </Button>

                  <MyVerticallyCenteredModal2
                    show={modalShow2}
                    onHide={() => setModalShow2(false)}
                  />
                </>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-10">
              <h5 class="card-title">Intrested in {project_name}</h5>
              <p class="card-text m-0">Builder Name: {builder_name}</p>
              <p class="card-text m-0">Message: {intrested}</p>
              <p class="card-text m-0">Address: {address_get}</p>
            </div>
            <div class="col-md-2">
              <p class="card-text text-end m-0">
                {date_get}/{month_get}/{year_get}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    // ))}
  );
};
export default Dashboard;
