import { React, useState } from "react";
// import { login } from './auth';
import { useNavigate, useLocation, Link } from "react-router-dom";
import "./css/login-style.css";
import loginlogo from "./images/logo.jpeg";
import firebase from "firebase";
import "firebase/auth";
import db from "./firebase";
import { useAuth } from "./auth";
import { Helmet } from "react-helmet";
import { Modal } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import successpopup from "../images/successpopup.svg";

const Login = () => {
  const navigation = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [passValidate, setPassValidate] = useState("");
  const [emailValidate, setEmailValidate] = useState("");
  const [port, setPort] = useState("");
  const [form, setForm] = useState({
    email: "",
    password: "",
    name: "",
    mob_no: "",
  });

  const TITLE = "cgc-admin";

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   await login(form, navigation);

  // }
  const location = useLocation();

  const redirectPath = location.state?.path || "/";
  const auth = useAuth();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form.email === "") {
      document.getElementById("email-validate").style.display = "block";
      document.getElementById("mob-validate").style.display = "none";
      document.getElementById("password-validate").style.display = "none";
      document.getElementById("port-validate").style.display = "none";
      setEmailValidate("Please enter a email");
    } else if (form.mob_no === "") {
      document.getElementById("mob-validate").style.display = "block";
      document.getElementById("email-validate").style.display = "none";
      document.getElementById("password-validate").style.display = "none";
      document.getElementById("port-validate").style.display = "none";
    } else if (port === "") {
      document.getElementById("mob-validate").style.display = "none";
      document.getElementById("email-validate").style.display = "none";
      document.getElementById("password-validate").style.display = "none";
      document.getElementById("port-validate").style.display = "block";
    } else if (form.password === "") {
      document.getElementById("password-validate").style.display = "block";
      document.getElementById("mob-validate").style.display = "none";
      document.getElementById("email-validate").style.display = "none";
      document.getElementById("port-validate").style.display = "none";
      setPassValidate("Please enter a password");
    } else if (form.password.length < 6) {
      document.getElementById("password-validate").style.display = "block";
      document.getElementById("mob-validate").style.display = "none";
      document.getElementById("email-validate").style.display = "none";
      document.getElementById("port-validate").style.display = "none";
      setPassValidate("Please enter your password is above six letters");
    } else {
      document.getElementById("password-validate").style.display = "none";
      document.getElementById("email-validate").style.display = "none";
      document.getElementById("mob-validate").style.display = "none";
      document.getElementById("port-validate").style.display = "none";
      let docCheck = await db
        .collection("Vendor_Details")
        .where("vendor_email", "==", form.email)
        .get();
      if (docCheck.empty) {
        console.log("empty");
        try {
          //Create User with Email and Password
          firebase
            .auth()
            .createUserWithEmailAndPassword(form.email, form.password)
            .then(store_data())
            .catch(function (error) {
              // Handle Errors here.
              var errorCode = error.code;
              var errorMessage = error.message;
              console.log(errorCode);
              console.log(errorMessage);
            });
        } catch (err) {
          console.error(err);
          alert(err.message);
        }
      } else {
        console.log("not empty");
        document.getElementById("email-validate").style.display = "block";
        setEmailValidate(
          "Enter a mail is already register please try another email"
        );
      }
    }
  };
  async function store_data() {
    let port_get = "";
    if (port === "CH") {
      port_get = "Chennai";
    } else {
      port_get = "Tutucorin";
    }
    let count = "";
    await db
      .collection("Vendor_Details")
      .where("port", "==", port_get)
      .get()
      .then((querySnapshot) => {
        count = querySnapshot.size + 1;
      });

    let data = {
      vendor_email: form.email,
      vendor_password: form.password,
      vendor_mob_no: form.mob_no,
      vendor_id: "TSC-VE-" + port + "-" + count,
      vendor_pass_book: "",
      vendor_aadhar: "",
      vendor_pan: "",
      shop_address: "",
      gst_num: "",
      port: port_get,
      joined_date: new Date(),
      approved_date: "",
      vendor_status: 0,
    };
    await db.collection("Vendor_Details").doc(form.mob_no).set(data);

    console.log("store data");
    setModalShow(true);
  }

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body class="text-center p-5">
          <img src={successpopup} style={{ width: "65px", height: "65px" }} />
          <h4 class="text-center mt-4">{"Congratulations !!"}</h4>
          <p class="text-center">
            Your account was successfully join to Sailors Choice
          </p>
          <p class="text-center">Click Continue to login</p>
          <Button onClick={props.onHide} className="btn-popup-sucess mt-3">
            <Link to="/login" color="white">
              Continue
            </Link>
          </Button>
        </Modal.Body>
      </Modal>
    );
  }
  return (
    <div class="h-100 background-img">
      <Helmet>
        <title>{"Sailors Choice Vendor Panel"}</title>
      </Helmet>
      <div class="container h-100">
        <div class="row h-100 justify-content-center align-items-center">
          <div class="col-12 col-md-10 col-sm-10 col-xl-8 col-lg-8">
            <div class="row login-card align-items-center">
              <div class="col-md-6">
                <div class="text-center">
                  <img src={loginlogo} width="250px" height="auto" />
                </div>
              </div>
              <div class="col-md-6">
                <h4>SignUp</h4>
                <form onSubmit={handleSubmit}>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Email address</label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      onChange={(e) =>
                        setForm({ ...form, email: e.target.value })
                      }
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                    />
                    <p
                      style={{ display: "none", color: "red" }}
                      id="email-validate"
                    >
                      {emailValidate}
                    </p>
                  </div>
                  <div class="form-group">
                    <label for="exampleInputName">Mobile Number</label>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputNumber"
                      onChange={(e) =>
                        setForm({ ...form, mob_no: e.target.value })
                      }
                      aria-describedby="emailHelp"
                      placeholder="Enter mobile number"
                    />
                    <p
                      style={{ display: "none", color: "red" }}
                      id="mob-validate"
                    >
                      Please enter a mobile number
                    </p>
                  </div>
                  <div className="mt-1">
                    <label>Select your port</label>
                    <Form.Select
                      onChange={(e) => setPort(e.target.value)}
                      size="sm"
                    >
                      <option value={""}>Select a port</option>
                      <option value="CH">Chennai-Ennore</option>
                      <option value="TU">Tutucorin</option>
                    </Form.Select>
                    <p
                      style={{ display: "none", color: "red" }}
                      id="port-validate"
                    >
                      Please select your port
                    </p>
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword1">Password</label>
                    <input
                      type="password"
                      class="form-control"
                      id="exampleInputPassword1"
                      onChange={(e) =>
                        setForm({ ...form, password: e.target.value })
                      }
                      placeholder="Password"
                    />
                    <p
                      style={{ display: "none", color: "red" }}
                      id="password-validate"
                    >
                      {passValidate}
                    </p>
                  </div>
                  <input type="checkbox"></input>
                  <label>
                    Please see our{" "}
                    <Link to="/terms-conditions">terms & conditons</Link>
                  </label>
                  <button
                    type="submit"
                    class="btn btn-primary w-100 mt-4 mb-2 h-40 br-full"
                  >
                    Submit
                  </button>
                </form>
                <div class="form-group">
                  <label>Already have a account?</label>{" "}
                  <Link to="/login">SignIn</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default Login;
