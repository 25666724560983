import React from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

function TermsCondtion() {
  const docs = [
    {
      uri: require("../files/ventor-terms.pdf"),
    },
  ];
  return (
    <div>
      <DocViewer
        documents={docs}
        initialActiveDocument={docs[1]}
        pluginRenderers={DocViewerRenderers}
      />
    </div>
  );
}

export default TermsCondtion;
