// Import section start
// Builders Projects add code in this js file
import { React, useState, useRef, useEffect } from "react";
import db from "./firebase";
import firebase from "firebase";
import $ from "jquery";
import plus from "./images/plus.svg";
import success from "./images/success.svg";
import successpopup from "../images/successpopup.svg";
import editpopup from "./images/editpopup.svg";
import { Modal } from "react-bootstrap";
import Loader from "react-js-loader";
import { Helmet } from "react-helmet";
import warning from "./images/warning.svg";
import { Button } from "react-bootstrap";
import "./css/validate.css";
import Autocomplete from "react-google-autocomplete";
import tooltip from "../images/tooltip.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useLocation,useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import jQuery from "jquery";
import { Container, Dropdown, DropdownButton } from "react-bootstrap";
import { Accordion } from "react-bootstrap";
// import section end

// Main function start
function SeoPage() {
  const TITLE = "cgc-admin";
  const [modalCheckBox, setCheckBoxalert] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [pageTitle,setPageTitle] = useState('');
  const [pageOgTitle,setPageOgTitle] = useState('');
  const [pageOgUrl,setPageOgUrl] = useState('');
  const [pageTwitterDescription,setPageTwitterDescription] = useState('');
  const [pageTwitterTitle,setPageTwitterTitle] = useState('');
  const [pageDescription,setPageDescription] = useState('');
  const [pageOgDescription,setPageOgDescription] = useState('');
  const [robotTag,setRobotTag] = useState([])

  const navigate = useNavigate();
  const location = useLocation();
  var state_get = location.state
  var pathname_get = location.pathname;
  var pathname_split = pathname_get.split(":")[1]

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  var pathname_need;
  if (pathname_split === "apartment" || pathname_split === "plot" || pathname_split === "villa" ||
  pathname_split === "property-category" || pathname_split === "status" ){
    pathname_need = capitalizeFirstLetter(pathname_split)+ " in";
  }else{
    pathname_need = ""
  }
  useEffect(() => {
    default_check_seo();
    Fetchdata()
    check_seo_content() 
  }, [array_declare_seo]);

  function Fetchdata(){
  
    if(state_get.category==="Home"){
      let docRef = db.collection("Seo_section").doc("Home-Home");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
                setPageOgTitle(data.opengraph.og_title)
                if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
                setPageOgUrl(data.opengraph.og_url)
                }
              } catch (error) {
                setPageOgUrl("https://cgch.in/")
                
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(state_get.category==="About us"){
      
      let docRef = db.collection("Seo_section").doc("About-us-About us");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
                setPageOgTitle(data.opengraph.og_title)
                if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
                setPageOgUrl(data.opengraph.og_url)
                }
              } catch (error) {
                setPageOgUrl("https://cgch.in/about-us")
                
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(state_get.category==="Privacy policy"){
      let docRef = db.collection("Seo_section").doc("Privacy-policy-Privacy policy");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
              setPageOgTitle(data.opengraph.og_title)
              if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
              setPageOgUrl(data.opengraph.og_url)
              }
              } catch (error) {
                setPageOgUrl("https://cgch.in/privacy-policy")
                
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(state_get.category==="Disclaimer"){
      let docRef = db.collection("Seo_section").doc("Disclaimer-Disclaimer");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
                setPageOgTitle(data.opengraph.og_title)
                if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
                setPageOgUrl(data.opengraph.og_url)
                }
              } catch (error) {
                setPageOgUrl("https://cgch.in/disclaimer")
                
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(state_get.category==="Contact"){
      let docRef = db.collection("Seo_section").doc("Contact-Contact");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
                setPageOgTitle(data.opengraph.og_title)
                if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
                setPageOgUrl(data.opengraph.og_url)
                }
              } catch (error) {
                setPageOgUrl("https://cgch.in/contact")
                
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(state_get.category==="Apartment"){
      let docRef = db.collection("Seo_section").doc("Apartment-Apartment");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
              setPageOgTitle(data.opengraph.og_title)
              if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
              setPageOgUrl(data.opengraph.og_url)
              }
              } catch (error) {
                setPageOgUrl("https://cgch.in/apartment")
                
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(state_get.category==="Plot"){
      let docRef = db.collection("Seo_section").doc("Plot-Plot");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
                setPageOgTitle(data.opengraph.og_title)
                if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
                setPageOgUrl(data.opengraph.og_url)
                }
              } catch (error) {
                setPageOgUrl("https://cgch.in/plot")
                
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(state_get.category==="Villa"){
      let docRef = db.collection("Seo_section").doc("Villa-Villa");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
                setPageOgTitle(data.opengraph.og_title)
                if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
                setPageOgUrl(data.opengraph.og_url)
                }
              } catch (error) {
                setPageOgUrl("https://cgch.in/villa")
                
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(pathname_split==="apartment" && state_get.category==="Chennai" ){
      let docRef = db.collection("Seo_section").doc("apartment-Chennai");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description);
              try {
                setPageOgDescription(data.opengraph.og_description)
                setPageOgTitle(data.opengraph.og_title)
                if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
                setPageOgUrl(data.opengraph.og_url)
                }
              } catch (error) {
                setPageOgUrl("https://cgch.in/apartment-chennai")
                
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(pathname_split==="apartment" && state_get.category==="Coimbatore" ){
      let docRef = db.collection("Seo_section").doc("apartment-Coimbatore");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
              setPageOgTitle(data.opengraph.og_title)
              if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
              setPageOgUrl(data.opengraph.og_url)
              }
              } catch (error) {
                setPageOgUrl("https://cgch.in/apartment-coimbatore")
                
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(pathname_split==="apartment" && state_get.category==="Bangalore" ){

      let docRef = db.collection("Seo_section").doc("apartment-Bangalore");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
                setPageOgTitle(data.opengraph.og_title)
                if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
                setPageOgUrl(data.opengraph.og_url)
                }
              } catch (error) {
                setPageOgUrl("https://cgch.in/apartment-bangalore")
                
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(pathname_split==="plot" && state_get.category==="Chennai" ){
      let docRef = db.collection("Seo_section").doc("plot-Chennai");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
                setPageOgTitle(data.opengraph.og_title)
                if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
                setPageOgUrl(data.opengraph.og_url)
                }
              } catch (error) {
                setPageOgUrl("https://cgch.in/plot-chennai")
                
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(pathname_split==="plot" && state_get.category==="Coimbatore" ){
      let docRef = db.collection("Seo_section").doc("plot-Coimbatore");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
                setPageOgTitle(data.opengraph.og_title)
                if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
                setPageOgUrl(data.opengraph.og_url)
                }
              } catch (error) {
                setPageOgUrl("https://cgch.in/plot-coimbatore")
                
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(pathname_split==="plot" && state_get.category==="Bangalore" ){
      let docRef = db.collection("Seo_section").doc("plot-Bangalore");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
                setPageOgTitle(data.opengraph.og_title)
                if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
                setPageOgUrl(data.opengraph.og_url)
                }
              } catch (error) {
                setPageOgUrl("https://cgch.in/plot-bangalore")
                
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(pathname_split==="villa" && state_get.category==="Chennai" ){
      let docRef = db.collection("Seo_section").doc("villa-Chennai");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
                setPageOgTitle(data.opengraph.og_title)
                if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
                setPageOgUrl(data.opengraph.og_url)
                }
              } catch (error) {
                setPageOgUrl("https://cgch.in/villa-chennai")
                
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
          //  else{
          //     }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(pathname_split==="villa" && state_get.category==="Coimbatore" ){
      let docRef = db.collection("Seo_section").doc("villa-Coimbatore");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
                setPageOgTitle(data.opengraph.og_title)
                if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
                setPageOgUrl(data.opengraph.og_url)
                }
              } catch (error) {
                setPageOgUrl("https://cgch.in/villa-coimbatore")
                
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
            //  else{
            //   }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(pathname_split==="villa" && state_get.category==="Bangalore" ){
      let docRef = db.collection("Seo_section").doc("villa-Bangalore");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
                setPageOgTitle(data.opengraph.og_title)
                if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
                setPageOgUrl(data.opengraph.og_url)
                }
              } catch (error) {
                setPageOgUrl("https://cgch.in/villa-bangalore")
                
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
            //  else{
            //   }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(pathname_split==="property-category" && state_get.category==="Luxury" ){
      let docRef = db.collection("Seo_section").doc("property-category-Luxury");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
                setPageOgTitle(data.opengraph.og_title)
      
                if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
                setPageOgUrl(data.opengraph.og_url)
                }  
              } catch (error) {
                setPageOgUrl("https://cgch.in/property-category/luxury")
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
              // else{
              // }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(pathname_split==="property-category" && state_get.category==="Ultra Luxury" ){
      let docRef = db.collection("Seo_section").doc("property-category-Ultra Luxury");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
                setPageOgTitle(data.opengraph.og_title)
                if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
                setPageOgUrl(data.opengraph.og_url)
                }
              } catch (error) {
                setPageOgUrl("https://cgch.in/property-category/ultra-luxury")
                
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {

          console.log("Error getting document:", error);
      });
    }
    else if(pathname_split==="property-category" && state_get.category==="Premium" ){
      let docRef = db.collection("Seo_section").doc("property-category-Premium");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
                setPageOgTitle(data.opengraph.og_title)
                if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
                setPageOgUrl(data.opengraph.og_url)
                }
              } catch (error) {
                setPageOgUrl("https://cgch.in/property-category/premium")

              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(pathname_split==="property-category" && state_get.category==="Ultra premium" ){
      let docRef = db.collection("Seo_section").doc("property-category-Ultra premium");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
              setPageOgTitle(data.opengraph.og_title)
              if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
              setPageOgUrl(data.opengraph.og_url)
              }
              } catch (error) {
                setPageOgUrl("https://cgch.in/property-category/ultra-premium")
                
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(pathname_split==="property-category" && state_get.category==="Affordable" ){
      setPageOgUrl("https://cgch.in/property-category/affordable")
      let docRef = db.collection("Seo_section").doc("property-category-Affordable");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
              setPageOgTitle(data.opengraph.og_title)
              if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
              setPageOgUrl(data.opengraph.og_url)
              }
              } catch (error) {
                setPageOgUrl("https://cgch.in/property-category/affordable")
                
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(pathname_split==="status" && state_get.category==="Ready to Built" ){

      let docRef = db.collection("Seo_section").doc("status-Ready to Built");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
              setPageOgTitle(data.opengraph.og_title)
              if(data.opengraph.og_url !== undefined && data.opengraph.og_url !==" "){
              setPageOgUrl(data.opengraph.og_url)
              }
              } catch (error) {
                setPageOgUrl("https://cgch.in/status/ready-to-built")
                
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
              // else{
              // }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(pathname_split==="status" && state_get.category==="New Launch" ){
      let docRef = db.collection("Seo_section").doc("status-New Launch");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
                setPageOgTitle(data.opengraph.og_title)
                if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
                setPageOgUrl(data.opengraph.og_url)
                }
              } catch (error) {
                setPageOgUrl("https://cgch.in/status/new-launch")
                
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
              // else{
              // }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(pathname_split==="status" && state_get.category==="Ongoing" ){
      let docRef = db.collection("Seo_section").doc("status-Ongoing");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              try {
                setPageOgDescription(data.opengraph.og_description)
                setPageOgTitle(data.opengraph.og_title)
                if(data.opengraph.og_url !== undefined || data.opengraph.og_url !==" "){
                setPageOgUrl(data.opengraph.og_url)
                }  
              } catch (error) {
                setPageOgUrl("https://cgch.in/status/ongoing")
                
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
              // else{
              // }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(pathname_split==="status" && state_get.category==="Ready to move" ){
      let docRef = db.collection("Seo_section").doc("status-Ready to move");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
              setPageOgDescription(data.opengraph.og_description)
              setPageOgTitle(data.opengraph.og_title)
              if(data.opengraph.og_url !== undefined && data.opengraph.og_url !==""){
              setPageOgUrl(data.opengraph.og_url)
              }else{
                setPageOgUrl("https://cgch.in/status/ready-to-move")
              }
              try {
                setPageTwitterDescription(data.twitter.twitter_description)
                setPageTwitterTitle(data.twitter.twitter_title)
              } catch (error) {
                
                setPageTwitterDescription("")
                setPageTwitterTitle("")
              }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
    else if(pathname_split==="status" && state_get.category==="Completed" ){
      let docRef = db.collection("Seo_section").doc("status-Completed");
    
      docRef.get().then((doc) => {
          if (doc.exists) {
              var data = doc.data();
              setPageTitle(data.seo.seo_title);
              setPageDescription(data.seo.seo_description)
             try {
              setPageOgDescription(data.opengraph.og_description)
              setPageOgTitle(data.opengraph.og_title)
              if(data.opengraph.og_url !== undefined && data.opengraph.og_url !==" "){
                setPageOgUrl(data.opengraph.og_url)
                }
             } catch (error) {
              setPageOgUrl("https://cgch.in/status/completed")
              
             }
             try {
              setPageTwitterDescription(data.twitter.twitter_description)
              setPageTwitterTitle(data.twitter.twitter_title)
            } catch (error) {
              
              setPageTwitterDescription("")
              setPageTwitterTitle("")
            }
        //  lse{
               
        //       }
              console.log("Document data:", doc.data(),data.seo.seo_description);
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
  }

  
  function default_check_seo() {
    document.getElementById("input-seo-follow").checked = true;
    document.getElementById("input-seo-index").checked = true;
    document.getElementById("input-seo-canonical").checked = true;
  }
  async function submit_data() {
    await check_seo_content();
    await check_seo_canonical();

    var title_get = document.getElementById("input_seo_title").value;
    var description_get = document.getElementById(
      "input_seo_description"
    ).value;
    var og_title_get = document.getElementById("input_seo_og_title").value;
    var og_description_get = document.getElementById(
      "input_seo_og_description"
    ).value;
    var og_url_get = document.getElementById("input_seo_og_url").value;
    var twitter_title_get = document.getElementById("input_seo_twitter_title").value;
    var twitter_card_get = document.getElementById("input_seo_twitter_card").value;
    var twitter_description_get = document.getElementById("input_seo_twitter_description").value;

    const data = {
      seo: {
        seo_robot: array_declare_seo,
        seo_title: title_get,
        seo_description: description_get,
        canonical_value: declare_canonical
      },
      opengraph:{
        og_title:og_title_get,
        og_description:og_description_get,
        og_url:og_url_get
      },
      twitter:{
        twitter_title:twitter_title_get,
        twitter_description:twitter_description_get,
        twitter_card:twitter_card_get
      }
    };
    await db.collection("Seo_section").doc(pathname_split+"-"+state_get.category).set(data);
    setModalShow(true);
    setTimeout(function refreshPage() {
      window.location.reload(false);
    }, 4000);
  }

  var array_declare_seo = [];
  function check_seo_content() {
    console.log("success");
    array_declare_seo = [];
    var checkedValue = null;
    var obj_seo = {};
    var inputElements = document.getElementsByClassName("single-checkbox");
    for (var i = 0; inputElements[i]; ++i) {
      if (inputElements[i].checked) {
        checkedValue = inputElements[i].value;

        obj_seo = { content: checkedValue };
        array_declare_seo.push(obj_seo);
      }
      console.log(array_declare_seo, "seo array");
    }
  }

  var declare_canonical = "";
  function check_seo_canonical() {
    console.log("success");
    declare_canonical = "";
    var checkedValue = null;
    var inputElements = document.getElementsByClassName("canonical-checkbox");
    for (var i = 0; inputElements[i]; ++i) {
      if (inputElements[i].checked) {
        checkedValue = inputElements[i].value;
        declare_canonical = checkedValue
      }
    }
  }
  // popup success start
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body class="text-center p-5">
          <img src={successpopup} style={{ width: "65px", height: "65px" }} />
          <h4 class="text-center mt-4">{}</h4>
          <p class="text-center">Your SEO content was updated successfully</p>
          <Button onClick={props.onHide} className="btn-popup-sucess mt-3">
            Continue
          </Button>
        </Modal.Body>
      </Modal>
    );
  }

  function MyVerticallyCheckBoxalert(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body class="text-center p-5">
          {/* <img src={successpopup} style={{ width: "65px", height: "65px", }} /> */}
          <h4 class="text-center mt-4">cgc-admin.web.app says</h4>
          <p class="text-center">allowed only 2</p>
          <Button onClick={props.onHide} className="btn-popup-edit mt-3">
            Continue
          </Button>
        </Modal.Body>
      </Modal>
    );
  }

  // end

  $(".single-checkbox").on("change", function (e) {
    console.log("calling for uncheck",$("input[type=checkbox]:checked"))
    if ($(".single-checkbox:checked").length > 2) {
      $(this).prop("checked", false);
      setCheckBoxalert(true);
    }
  });

  // $(".canonical-checkbox").on("change", function (e) {
  //   if ($("input[type=checkbox]:checked").length > 2) {
  //     $(this).prop("checked", false);
  //     setCheckBoxalert(true);
  //   }
  // });

  // drop down button function start
  (function ($) {
    // Begin jQuery
    $(function () {
      // DOM ready
      // If a link has a dropdown, add sub menu toggle.
      $("nav ul li a:not(:only-child)").click(function (e) {
        $(this).siblings(".nav-dropdown").toggle();
        // Close one dropdown when selecting another
        $(".nav-dropdown").not($(this).siblings()).hide();
        e.stopPropagation();
      });
      // Clicking away from dropdown will remove the dropdown class
      $("html").click(function () {
        $(".nav-dropdown").hide();
      });
      // Toggle open and close nav styles on click
      $("#nav-toggle").click(function () {
        $("nav ul").slideToggle();
      });
      // Hamburger to X toggle
      $("#nav-toggle").on("click", function () {
        this.classList.toggle("active");
      });
    }); // end DOM ready
  })(jQuery); // end jQuery

  function Seo_Category_Click(pass_data){
    setTimeout(function refreshPage() {
      window.location.reload(false);
    }, 1000);
    let category_ = pass_data
    let data1=pass_data.trim().split(' ')
    var trim_data =data1.join('-')
    navigate(`/seo-category/:${trim_data}`
    ,{state:{category:category_}})
    
  }
  function Seo_Category_Click_(pass_data,cate){
    setTimeout(function refreshPage() {
      window.location.reload(false);
    }, 1000);
    let category_ = pass_data
    let data1=cate.trim().split(' ')
    var trim_data =data1.join('-')
    navigate(`/seo-category/:${trim_data}`
    ,{state:{category:pass_data}})
    
  }

  function sameContentOgGraph(){
    setPageOgTitle(pageTitle)
    setPageOgDescription(pageDescription)
  }
  
  function sameContentTwitter(){
    setPageTwitterTitle(pageTitle)
    setPageTwitterDescription(pageDescription)
  }
  return (
    <div>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="container">
        <div className="row">
                <div class="container mt-5 mb-4">
              <div class="row">
                <div class="col-md-12">
                  <h4>SEO</h4>
                </div>
              </div>
            </div>
          <div className="col-md-3">
           
              {/* SEO section Start */}
              <div class="card mb-4">
                <div class="card-header">
                  <h5 class="m-0">Menu</h5>
                </div>
                <div class="card-body seo-menu">
               
                    <p class="seo-border-bottom"><a onClick={()=>Seo_Category_Click("Home")} style={{ height: "58px" }}> Home</a></p>
                    <p class="seo-border-bottom"><a onClick={()=>Seo_Category_Click("Apartment")} style={{ height: "58px" }}>Apartment</a></p>
                    <p class="seo-border-bottom"><a onClick={()=>Seo_Category_Click("Plot")} style={{ height: "58px" }}>Plot</a></p>
                    <p class="seo-border-bottom"><a onClick={()=>Seo_Category_Click("Villa")} style={{ height: "58px" }}>Villa</a></p>
                    <p class="seo-border-bottom"><a onClick={()=>Seo_Category_Click("About us")} style={{ height: "58px" }} >About us</a></p>
                    <p class="seo-border-bottom"><a onClick={()=>Seo_Category_Click("Disclaimer")} style={{ height: "58px" }}>Disclaimer</a></p>
                    <p class="seo-border-bottom"><a onClick={()=>Seo_Category_Click("Privacy policy")} style={{ height: "58px" }}>Privacy policy</a></p>
                    <p class="seo-border-bottom"><a onClick={()=>Seo_Category_Click("Contact")} style={{ height: "58px" }}>Contact</a></p>
                
                    <Accordion>
                      <Accordion.Item eventKey="0" className="seo-border-bottom">
                        <Accordion.Header>Apartment</Accordion.Header>
                        <Accordion.Body>
                          <p class="mb-1"><a onClick={()=>Seo_Category_Click_("Chennai","apartment")}>Chennai</a></p>
                          <p class="mb-1"><a onClick={()=>Seo_Category_Click_("Coimbatore","apartment")}>Coimbatore</a></p>
                          <p class="mb-1"> <a onClick={()=>Seo_Category_Click_("Bangalore","apartment")}>Bangalore</a></p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1" className="seo-border-bottom">
                        <Accordion.Header>Plot</Accordion.Header>
                        <Accordion.Body>
                          <p class="mb-1"><a onClick={()=>Seo_Category_Click_("Chennai","plot")}>Chennai</a></p>
                          <p class="mb-1"><a onClick={()=>Seo_Category_Click_("Coimbatore","plot")}>Coimbatore</a></p>
                          <p class="mb-1"><a onClick={()=>Seo_Category_Click_("Bangalore","plot")}>Bangalore</a></p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2" className="seo-border-bottom">
                        <Accordion.Header>Villa</Accordion.Header>
                        <Accordion.Body>
                          <p class="mb-1"><a onClick={()=>Seo_Category_Click_("Chennai","villa")}>Chennai</a></p>
                          <p class="mb-1"><a onClick={()=>Seo_Category_Click_("Coimbatore","villa")}>Coimbatore</a></p>
                          <p class="mb-1"><a onClick={()=>Seo_Category_Click_("Bangalore","villa")}>Bangalore</a></p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3" className="seo-border-bottom">
                        <Accordion.Header>Property category</Accordion.Header>
                        <Accordion.Body>
                          <p class="mb-1"><a onClick={()=>Seo_Category_Click_("Luxury","property category")}>Luxury</a></p>
                          <p class="mb-1"><a onClick={()=>Seo_Category_Click_("Ultra Luxury","property category")}>Ultra Luxury</a></p>
                          <p class="mb-1"><a onClick={()=>Seo_Category_Click_("Premium","property category")}>Premium</a></p>
                          <p class="mb-1"><a onClick={()=>Seo_Category_Click_("Ultra premium","property category")}>Ultra premium</a></p>
                          <p class="mb-1"><a onClick={()=>Seo_Category_Click_("Affordable","property category")}>Affordable</a></p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4" className="seo-border-bottom-last">
                        <Accordion.Header>Status</Accordion.Header>
                        <Accordion.Body>
                          <p class="mb-1"><a onClick={()=>Seo_Category_Click_("Ready to Built","status")}>Ready to Built</a></p>
                          <p class="mb-1"><a onClick={()=>Seo_Category_Click_("New Launch","status")}>New Launch</a></p>
                          <p class="mb-1"><a onClick={()=>Seo_Category_Click_("Ongoing","status")}>Ongoing</a></p>
                          <p class="mb-1"><a onClick={()=>Seo_Category_Click_("Ready to move","status")}>Ready to move</a></p>
                          <p class="mb-1"><a onClick={()=>Seo_Category_Click_("Completed","status")}>Completed</a></p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  
                </div>
              </div>
              {/* SEO section end */}
            
          </div>
          <div className="col-md-9">
      
            
              {/* SEO section Start */}
              <div class="card mb-4">
                <div class="card-header">
                  <h5 class="m-0">{pathname_need}{" "}{state_get.category} Page-SEO</h5>
                </div>
                <div class="card-body mb-4">
                  <div class="row">
                    <div class="form-group col-md-12">
                      <label for="inputState">Title</label>
                      <input
                        type="text"
                        class="form-control"
                        id="input_seo_title"
                        value={pageTitle}
                        onChange={(e)=>setPageTitle(e.target.value)}
                      />
                      <p className="validate_hint">
                        This is what will appear in the first line when this
                        post shows up in the search results.
                      </p>
                    </div>
                    <div class="form-group col-md-12">
                      <label for="inputState">Description</label>
                      <textarea
                        type="text"
                        class="form-control h-200"
                        id="input_seo_description"
                        value={pageDescription}
                        onChange={(e)=>setPageDescription(e.target.value)}
                      ></textarea>
                      <p className="validate_hint">
                        This is what will appear as the description when this
                        post shows up in the search results.
                      </p>
                    </div>
                    <div class="form-group col-md-12 ceo-content">
                      <label for="inputState">Robots Meta</label>
                      <div className="row">
                        <div class="form-check col-md-4">
                          <input
                            className="single-checkbox "
                            type="checkbox"
                            id="input-seo-index"
                            name="vehicle1"
                            value="index"
                          />
                          <label for="vehicle1"> Index</label>
                          <OverlayTrigger
                            key={"top"}
                            placement={"top"}
                            overlay={
                              <Tooltip id={`tooltip-${"top"}`}>
                                Instructs search engines to index and how these
                                pages in the search results.
                              </Tooltip>
                            }
                          >
                            <img
                              src={tooltip}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </OverlayTrigger>
                        </div>

                        <div class="form-check col-md-4">
                          <input
                            className="single-checkbox "
                            type="checkbox"
                            id="input-seo-follow"
                            name="vehicle1"
                            value="follow"
                          />
                          <label for="vehicle1 ">Follow</label>
                          <OverlayTrigger
                            key={"top"}
                            placement={"top"}
                            overlay={
                              <Tooltip id={`tooltip-${"top"}`}>
                                Instructs search engines to follow links on the
                                pages
                              </Tooltip>
                            }
                          >
                            <img
                              src={tooltip}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </OverlayTrigger>
                        </div>

                        <div class="form-check d-none col-md-4">
                          <input
                            className="single-checkbox "
                            type="checkbox"
                            id="input-seo-follow"
                            name="vehicle1"
                            value="follow"
                          />
                          <label for="vehicle1 ">No Image Index</label>
                          <OverlayTrigger
                            key={"top"}
                            placement={"top"}
                            overlay={
                              <Tooltip id={`tooltip-${"top"}`}>
                                Prevents images on a page from being indexed by
                                Google and other search engines
                              </Tooltip>
                            }
                          >
                            <img
                              src={tooltip}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </OverlayTrigger>
                        </div>

                        <div className="form-check col-md-4">
                          <input
                            className="single-checkbox "
                            type="checkbox"
                            id="input-seo-no-index"
                            name="vehicle2"
                            value="noindex"
                          />
                          <label for="vehicle2"> No Index</label>
                          <OverlayTrigger
                            key={"top"}
                            placement={"top"}
                            overlay={
                              <Tooltip id={`tooltip-${"top"}`}>
                                Prevents pages from being indexed and displayed
                                in search engine result pages
                              </Tooltip>
                            }
                          >
                            <img
                              src={tooltip}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </OverlayTrigger>
                        </div>
                        <div className="form-check col-md-4">
                          <input
                            className="single-checkbox"
                            type="checkbox"
                            id="input-seo-no-follow"
                            name="vehicle2"
                            value="nofollow"
                          />
                          <label for="vehicle2"> No Follow</label>
                          <OverlayTrigger
                            key={"top"}
                            placement={"top"}
                            overlay={
                              <Tooltip id={`tooltip-${"top"}`}>
                                Prevents search engines from following links on
                                the pages
                              </Tooltip>
                            }
                          >
                            <img
                              src={tooltip}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-12 ceo-content">
                      <label for="inputState">Canonical Tag</label>
                      <div className="row">
                        <div class="form-check col-md-4">
                          <input
                            className="canonical-checkbox "
                            type="checkbox"
                            id="input-seo-canonical"
                            name="vehicle1"
                            value="canonical"
                          />
                          <label for="vehicle1"> Canonical</label>
                          <OverlayTrigger
                            key={"top"}
                            placement={"top"}
                            overlay={
                              <Tooltip id={`tooltip-${"top"}`}>
                    Instruct search engines that a specific URL represents the master copy of a page.
                              </Tooltip>
                            }
                          >
                            <img
                              src={tooltip}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* SEO section end */}

   {/* SEO opengraph section Start */}
   <div class="card mb-4">
                <div class="card-header">
                  <div class="row">
                    <div className="col-md-8">
                    <h5 class="m-0">Opengraph Section</h5>
                    </div>
                    <div className="col-md-4 text-end">
                    <button className="btn-match m-0" onClick={()=>sameContentOgGraph()}>Same content</button>
                    </div>
                  </div>         
                </div>
                <div class="card-body mb-4">
                  <div class="row">
                    <div class="form-group col-md-12">
                      <label for="inputState">Og-Title</label>
                      <input
                        type="text"
                        class="form-control"
                        id="input_seo_og_title"
                        value={pageOgTitle}
                        onChange={(e)=>setPageOgTitle(e.target.value)}
                      />
                      <p className="validate_hint">
                        This is what will appear in the first line when this
                        post shows up in the social media.
                      </p>
                    </div>
                    <div class="form-group col-md-12">
                      <label for="inputState">Og-Description</label>
                      <textarea
                        type="text"
                        class="form-control h-200"
                        id="input_seo_og_description"
                        value={pageOgDescription}
                        onChange={(e)=>setPageOgDescription(e.target.value)}
                      ></textarea>
                      <p className="validate_hint">
                        This is what will appear as the description when this
                        post shows up in the search results.
                      </p>
                    </div>
                    <div class="form-group col-md-12">
                      <label for="inputState">Og-url</label>
                      <input
                        type="text"
                        class="form-control"
                        id="input_seo_og_url"
                        value={pageOgUrl}
                        onChange={(e)=>setPageOgUrl(e.target.value)}
                        disabled
                      />
                      <p className="validate_hint">
                        This is what will appear in the first line when this
                        post shows up in the social media.
                      </p>
                    </div>
                    {/* <div class="form-group col-md-12 ceo-content">
                      <label for="inputState">Robots Meta</label>
                      <div className="row">
                        <div class="form-check col-md-4">
                          <input
                            className="single-checkbox "
                            type="checkbox"
                            id="input-seo-index"
                            name="vehicle1"
                            value="index"
                          />
                          <label for="vehicle1"> Index</label>
                          <OverlayTrigger
                            key={"top"}
                            placement={"top"}
                            overlay={
                              <Tooltip id={`tooltip-${"top"}`}>
                                Instructs search engines to index and how these
                                pages in the search results.
                              </Tooltip>
                            }
                          >
                            <img
                              src={tooltip}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </OverlayTrigger>
                        </div>

                        <div class="form-check col-md-4">
                          <input
                            className="single-checkbox "
                            type="checkbox"
                            id="input-seo-follow"
                            name="vehicle1"
                            value="follow"
                          />
                          <label for="vehicle1 ">Follow</label>
                          <OverlayTrigger
                            key={"top"}
                            placement={"top"}
                            overlay={
                              <Tooltip id={`tooltip-${"top"}`}>
                                Instructs search engines to follow links on the
                                pages
                              </Tooltip>
                            }
                          >
                            <img
                              src={tooltip}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </OverlayTrigger>
                        </div>

                        <div class="form-check col-md-4">
                          <input
                            className="single-checkbox "
                            type="checkbox"
                            id="input-seo-follow"
                            name="vehicle1"
                            value="follow"
                          />
                          <label for="vehicle1 ">No Image Index</label>
                          <OverlayTrigger
                            key={"top"}
                            placement={"top"}
                            overlay={
                              <Tooltip id={`tooltip-${"top"}`}>
                                Prevents images on a page from being indexed by
                                Google and other search engines
                              </Tooltip>
                            }
                          >
                            <img
                              src={tooltip}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </OverlayTrigger>
                        </div>

                        <div className="form-check col-md-4">
                          <input
                            className="single-checkbox "
                            type="checkbox"
                            id="input-seo-no-index"
                            name="vehicle2"
                            value="noindex"
                          />
                          <label for="vehicle2"> No Index</label>
                          <OverlayTrigger
                            key={"top"}
                            placement={"top"}
                            overlay={
                              <Tooltip id={`tooltip-${"top"}`}>
                                Prevents pages from being indexed and displayed
                                in search engine result pages
                              </Tooltip>
                            }
                          >
                            <img
                              src={tooltip}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </OverlayTrigger>
                        </div>
                        <div className="form-check col-md-4">
                          <input
                            className="single-checkbox "
                            type="checkbox"
                            id="input-seo-no-follow"
                            name="vehicle2"
                            value="nofollow"
                          />
                          <label for="vehicle2"> No Follow</label>
                          <OverlayTrigger
                            key={"top"}
                            placement={"top"}
                            overlay={
                              <Tooltip id={`tooltip-${"top"}`}>
                                Prevents search engines from following links on
                                the pages
                              </Tooltip>
                            }
                          >
                            <img
                              src={tooltip}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div> */}
                    {/* <div class="form-group col-md-12 ceo-content">
                      <label for="inputState">Canonical Tag</label>
                      <div className="row">
                        <div class="form-check col-md-4">
                          <input
                            className="canonical-checkbox "
                            type="checkbox"
                            id="input-seo-canonical"
                            name="vehicle1"
                            value="canonical"
                          />
                          <label for="vehicle1"> Canonical</label>
                          <OverlayTrigger
                            key={"top"}
                            placement={"top"}
                            overlay={
                              <Tooltip id={`tooltip-${"top"}`}>
                                Instructs search engines to index and how these
                                pages in the search results.
                              </Tooltip>
                            }
                          >
                            <img
                              src={tooltip}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* SEO section opengraph end */}

                {/* SEO twitter section Start */}
   <div class="card mb-4">
                <div class="card-header">
                <div class="row">
                    <div className="col-md-8">
                    <h5 class="m-0">Twitter Section</h5>
                    </div>
                    <div className="col-md-4 text-end">
                    <button className="btn-match m-0" onClick={()=>sameContentTwitter()}>Same content</button>
                    </div>
                  </div>   
                </div>
                <div class="card-body mb-4">
                  <div class="row">
                    <div class="form-group col-md-12">
                      <label for="inputState">Twitter-Title</label>
                      <input
                        type="text"
                        class="form-control"
                        id="input_seo_twitter_title"
                        value={pageTwitterTitle}
                        onChange={(e)=>setPageTwitterTitle(e.target.value)}
                      />
                      <p className="validate_hint">
                        This is what will appear in the first line when this
                        post shows up in the twitter.
                      </p>
                    </div>
                    <div class="form-group col-md-12">
                      <label for="inputState">Twitter-Description</label>
                      <textarea
                        type="text"
                        class="form-control h-200"
                        id="input_seo_twitter_description"
                        value={pageTwitterDescription}
                        onChange={(e)=>setPageTwitterDescription(e.target.value)}
                      ></textarea>
                      <p className="validate_hint">
                        This is what will appear as the description when this
                        post shows up in the twitter.
                      </p>
                    </div>
                    <div class="form-group col-md-12">
                      <label for="inputState">Twitter-card</label>
                      <input
                        type="text"
                        class="form-control"
                        id="input_seo_twitter_card"
                        value="summary_large_image"
                        disabled
                        // onChange={(e)=>setPageTwitterUrl(e.target.value)}
                      />
                      <p className="validate_hint">
                        This is what will appear in the first line when this
                        post shows up in the twitter.
                      </p>
                    </div>
                    {/* <div class="form-group col-md-12 ceo-content">
                      <label for="inputState">Robots Meta</label>
                      <div className="row">
                        <div class="form-check col-md-4">
                          <input
                            className="single-checkbox "
                            type="checkbox"
                            id="input-seo-index"
                            name="vehicle1"
                            value="index"
                          />
                          <label for="vehicle1"> Index</label>
                          <OverlayTrigger
                            key={"top"}
                            placement={"top"}
                            overlay={
                              <Tooltip id={`tooltip-${"top"}`}>
                                Instructs search engines to index and how these
                                pages in the search results.
                              </Tooltip>
                            }
                          >
                            <img
                              src={tooltip}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </OverlayTrigger>
                        </div>

                        <div class="form-check col-md-4">
                          <input
                            className="single-checkbox "
                            type="checkbox"
                            id="input-seo-follow"
                            name="vehicle1"
                            value="follow"
                          />
                          <label for="vehicle1 ">Follow</label>
                          <OverlayTrigger
                            key={"top"}
                            placement={"top"}
                            overlay={
                              <Tooltip id={`tooltip-${"top"}`}>
                                Instructs search engines to follow links on the
                                pages
                              </Tooltip>
                            }
                          >
                            <img
                              src={tooltip}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </OverlayTrigger>
                        </div>

                        <div class="form-check col-md-4">
                          <input
                            className="single-checkbox "
                            type="checkbox"
                            id="input-seo-follow"
                            name="vehicle1"
                            value="follow"
                          />
                          <label for="vehicle1 ">No Image Index</label>
                          <OverlayTrigger
                            key={"top"}
                            placement={"top"}
                            overlay={
                              <Tooltip id={`tooltip-${"top"}`}>
                                Prevents images on a page from being indexed by
                                Google and other search engines
                              </Tooltip>
                            }
                          >
                            <img
                              src={tooltip}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </OverlayTrigger>
                        </div>

                        <div className="form-check col-md-4">
                          <input
                            className="single-checkbox "
                            type="checkbox"
                            id="input-seo-no-index"
                            name="vehicle2"
                            value="noindex"
                          />
                          <label for="vehicle2"> No Index</label>
                          <OverlayTrigger
                            key={"top"}
                            placement={"top"}
                            overlay={
                              <Tooltip id={`tooltip-${"top"}`}>
                                Prevents pages from being indexed and displayed
                                in search engine result pages
                              </Tooltip>
                            }
                          >
                            <img
                              src={tooltip}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </OverlayTrigger>
                        </div>
                        <div className="form-check col-md-4">
                          <input
                            className="single-checkbox "
                            type="checkbox"
                            id="input-seo-no-follow"
                            name="vehicle2"
                            value="nofollow"
                          />
                          <label for="vehicle2"> No Follow</label>
                          <OverlayTrigger
                            key={"top"}
                            placement={"top"}
                            overlay={
                              <Tooltip id={`tooltip-${"top"}`}>
                                Prevents search engines from following links on
                                the pages
                              </Tooltip>
                            }
                          >
                            <img
                              src={tooltip}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div> */}
                    {/* <div class="form-group col-md-12 ceo-content">
                      <label for="inputState">Canonical Tag</label>
                      <div className="row">
                        <div class="form-check col-md-4">
                          <input
                            className="canonical-checkbox "
                            type="checkbox"
                            id="input-seo-canonical"
                            name="vehicle1"
                            value="canonical"
                          />
                          <label for="vehicle1"> Canonical</label>
                          <OverlayTrigger
                            key={"top"}
                            placement={"top"}
                            overlay={
                              <Tooltip id={`tooltip-${"top"}`}>
                                Instructs search engines to index and how these
                                pages in the search results.
                              </Tooltip>
                            }
                          >
                            <img
                              src={tooltip}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* SEO section twitter end */}

              <div class="d-flex justify-content-end pb-3">
                <div>
                  <button
                    onClick={() => submit_data()}
                    type="submit"
                    class="btn-red mt-3 border-full"
                  >
                    Submit
                  </button>
                </div>
              </div>
              <>
                <MyVerticallyCenteredModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </>
              <>
                <MyVerticallyCheckBoxalert
                  show={modalCheckBox}
                  onHide={() => setCheckBoxalert(false)}
                />
              </>
            
          </div>
        </div>
      </div>
    </div>
  );
}

// Main function end
export default SeoPage;
