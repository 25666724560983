import firebase from 'firebase';
import { collection, query, where, getDocs } from "firebase/firestore";
import { doc, deleteDoc } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyBL32TkOcDWg7Rwk_AZDOJJ-WYvOsOMrvA",
  authDomain: "thesailorschoiceonline.firebaseapp.com",
  projectId: "thesailorschoiceonline",
  storageBucket: "thesailorschoiceonline.appspot.com",
  messagingSenderId: "887084168111",
  appId: "1:887084168111:web:aa3fe62f44317ec655f55a",
  measurementId: "G-GX0HJ5C7NV"
};

firebase.initializeApp(firebaseConfig);
var db = firebase.firestore();

export default db;

export const auth = firebase.auth();
