// import section
// brochure submitdetails view on this page
import { React, useState, useEffect } from "react";
import db from "./firebase";
import { useNavigate } from "react-router-dom";
import "./css/styles.css";
import phone from "./images/phone.svg";
import trash from "./images/trash.svg";
import { Button } from "react-bootstrap";
import deletepopup from "../images/deletepopup.svg";
import { Modal } from "react-bootstrap";
import norecord from "./images/no record found.svg";
import firebase from "firebase";
import $ from "jquery";

let passbook_url = "";
let pan_url = "";
let aadhar_url = "";
// Main function start
function Brochure() {
  const [info, setInfo] = useState([]);
  const [fullinfo, setFullinfo] = useState([]);
  const [buildername, setBuilderName] = useState([]);
  const [vendorDoc, setVendorDoc] = useState("");
  const [address, setAddress] = useState("");
  const [gstNum, setGstNum] = useState("");
  const [name, setName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [branchName, setBranchName] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [panNumber, setPanNumber] = useState("");

  useEffect(() => {
    Fetchdata();
  }, []);

  // brochure data fetching
  const Fetchdata = async () => {
    let vendor_email = await localStorage.getItem("email");

    db.collection("Vendor_Details")
      .where("vendor_email", "==", vendor_email)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          setVendorDoc(doc.id);
          setInfo(doc.data());
          setVendorName(doc.data().vendor_name);
          setAddress(doc.data().shop_address);
          setPanNumber(doc.data().panNumber);
          setBranchName(doc.data().branchName);
          setGstNum(doc.data().gst_num);
          setAccountNumber(doc.data().account_number);
          setIfsc(doc.data().ifsc);
          setBranchName(doc.data().branchName);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };

  const [panImg, setPanImg] = useState("");
  const [panPreImg, setPanPreImg] = useState("");
  const [aadharImg, setAadharImg] = useState("");
  const [aadharPreImg, setAadharPreImg] = useState("");
  const [passImg, setPassImg] = useState("");
  const [passPreImg, setPassPreImg] = useState("");

  const pancardChange = (e) => {
    if (e.target.files[0]) {
      setPanImg(e.target.files[0]);
      // setPreviewproimg(true);
      let reader = new FileReader();
      reader.onloadend = () => {
        //   this.setState({
        //     imagePreviewUrl: reader.result
        //   });
        setPanPreImg(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const aadharChange = (e) => {
    if (e.target.files[0]) {
      setAadharImg(e.target.files[0]);
      // setPreviewproimg(true);
      let reader = new FileReader();
      reader.onloadend = () => {
        //   this.setState({
        //     imagePreviewUrl: reader.result
        //   });
        setAadharPreImg(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const bankImageChange = (e) => {
    if (e.target.files[0]) {
      setPassImg(e.target.files[0]);
      // setPreviewproimg(true);
      let reader = new FileReader();
      reader.onloadend = () => {
        //   this.setState({
        //     imagePreviewUrl: reader.result
        //   });
        setPassPreImg(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const groupBy = (key) => (array) =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

  const groupBycity = groupBy("city");
  var split_group_by_builder_city = groupBycity(fullinfo);

  const groupByarea = groupBy("builder_area");
  var split_group_by_builder_area = groupByarea(fullinfo);

  const handleUploadPass = async () => {
    let file = passImg;
    var storage = firebase.storage();
    var storageRef = storage.ref();
    var uploadTask = await storageRef
      .child("vendor/" + vendorDoc + "/" + file.name)
      .put(file)
      .then((response) => response.ref.getDownloadURL());
    passbook_url = uploadTask;
  };
  const handleUploadPan = async () => {
    let file = panImg;
    var storage = firebase.storage();
    var storageRef = storage.ref();
    var uploadTask = await storageRef
      .child("vendor/" + vendorDoc + "/" + file.name)
      .put(file)
      .then((response) => response.ref.getDownloadURL());
    pan_url = uploadTask;
  };
  const handleUploadAadhar = async () => {
    let file = aadharImg;
    var storage = firebase.storage();
    var storageRef = storage.ref();
    var uploadTask = await storageRef
      .child("vendor/" + vendorDoc + "/" + file.name)
      .put(file)
      .then((response) => response.ref.getDownloadURL());
    aadhar_url = uploadTask;
  };
  async function submitKyc() {
    try {
      await handleUploadPass();
      await handleUploadPan();
      await handleUploadAadhar();
    } catch (error) {}

    var washingtonRef = db.collection("Vendor_Details").doc(vendorDoc);

    // Set the "capital" field of the city 'DC'
    return washingtonRef
      .update({
        shop_address: address,
        vendor_aadhar: aadhar_url,
        vendor_pass_book: passbook_url,
        vendor_pan: pan_url,
        gst_num: gstNum,
        vendor_status: info.vendor_status === 0 ? 2 : info.vendor_status,
        account_number: accountNumber,
        ifsc: ifsc,
        branchName: branchName,
        panNumber: panNumber,
        vendor_name: vendorName,
      })
      .then(() => {
        if (info.vendor_status === 0 || info.vendor_status !== 1) {
          var settings = {
            async: true,
            crossDomain: true,
            url: `https://www.fast2sms.com/dev/bulkV2?authorization=YX9TfQk4yRNqk78SRiLWLDF3LIUMcTMshWZOlCjLAYVTSrHUc1n0SD8EtWjU
          &sender_id=TXTIND&message=New vendor signUp request mobNo-${info.vendor_mob_no}&route=v3&
          numbers=9750593005`,
            method: "GET",
          };

          $.ajax(settings)
            .done(function (response) {
              console.log(response);
            })
            .then(() => {
              localStorage.setItem("vendor_name", vendorName);
              localStorage.setItem("vendor_address", address);
              localStorage.setItem("vendor_mob", info.vendor_mob_no);
              console.log("Document successfully updated!");
              window.location.reload(false);
            });
        } else {
          localStorage.setItem("vendor_name", vendorName);
          localStorage.setItem("vendor_address", address);
          localStorage.setItem("vendor_mob", info.vendor_mob_no);
          console.log("Document successfully updated!");
          window.location.reload(false);
        }
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  }
  return (
    <div>
      <div className="container">
        <div className="row">
          <div class="container mt-5 mb-4">
            <div class="row">
              <div class="col-md-12">
                <h4>Account & KYC Verification</h4>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            {/* SEO section Start */}
            <div class="card mb-4">
              <div class="card-header">
                <h5 class="m-0">Account</h5>
              </div>
              <div class="card-body seo-menu">
                <p>Status:</p>
                <input
                  value={
                    info.vendor_status === 0
                      ? "registered"
                      : info.vendor_status === 1
                      ? "approved"
                      : info.vendor_status === 2
                      ? "pending"
                      : "declined"
                  }
                  disabled
                ></input>
                <p className="mt-2">Email:</p>
                <input value={info.vendor_email} disabled></input>
                <p className="mt-2">Mobile Number:</p>
                <input value={info.vendor_mob_no} disabled></input>
              </div>
            </div>
            {/* SEO section end */}
          </div>
          {info.vendor_status === 2 ? (
            <div className="col-md-9 text-center">
              <img
                alt="pending"
                className="mt-3"
                src={
                  "https://firebasestorage.googleapis.com/v0/b/thesailorschoiceonline.appspot.com/o/test%2F3044640-200.png?alt=media&token=a41db7ac-745f-40ef-b8e2-1637067e4be7"
                }
                height="200px"
              ></img>
            </div>
          ) : (
            <div className="col-md-9">
              {/* SEO section Start */}
              <div class="card mb-4">
                <div class="card-header">
                  <h5 class="m-0">KYC Verification</h5>
                </div>
                <div class="card-body mb-4">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label for="inputState">Vendor name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="input_seo_title"
                        onChange={(e) => setVendorName(e.target.value)}
                        value={vendorName}
                      />
                      <p className="validate_hint" style={{ display: "none" }}>
                        Verify Success
                      </p>
                      <p className="validate_hint" style={{ display: "none" }}>
                        Verify Failure
                      </p>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputState">Shop Address</label>
                      <input
                        type="text"
                        class="form-control"
                        id="input_seo_title"
                        onChange={(e) => setAddress(e.target.value)}
                        value={address}
                      />
                      <p className="validate_hint" style={{ display: "none" }}>
                        Verify Success
                      </p>
                      <p className="validate_hint" style={{ display: "none" }}>
                        Verify Failure
                      </p>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputState">GST number</label>
                      <input
                        type="text"
                        class="form-control"
                        id="input_seo_title"
                        onChange={(e) => setGstNum(e.target.value)}
                        value={gstNum}
                      />
                      <p className="validate_hint" style={{ display: "none" }}>
                        Verify Success
                      </p>
                      <p className="validate_hint" style={{ display: "none" }}>
                        Verify Failure
                      </p>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputState">Pan Number</label>
                      <input
                        type="text"
                        class="form-control"
                        id="input_seo_title"
                        onChange={(e) => setPanNumber(e.target.value)}
                        value={panNumber}
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputState">Bank account number</label>
                      <input
                        type="text"
                        class="form-control"
                        id="input_seo_title"
                        onChange={(e) => setAccountNumber(e.target.value)}
                        value={accountNumber}
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputState">Ifsc number</label>
                      <input
                        type="text"
                        class="form-control"
                        id="input_seo_title"
                        onChange={(e) => setIfsc(e.target.value)}
                        value={ifsc}
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputState">Bank name and branch</label>
                      <input
                        type="text"
                        class="form-control"
                        id="input_seo_title"
                        onChange={(e) => setBranchName(e.target.value)}
                        value={branchName}
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Bank Passbook image</label>
                      <input
                        type="file"
                        class="form-control"
                        id="input_project_img"
                        onChange={(e) => bankImageChange(e)}
                      />

                      {/* <button onClick={handleUpload}>click</button> */}
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Pancard image</label>
                      <input
                        type="file"
                        class="form-control"
                        id="input_project_img"
                        onChange={(e) => pancardChange(e)}
                      />

                      {/* <button onClick={handleUpload}>click</button> */}
                    </div>
                    <div class="form-group col-md-6">
                      <label for="inputEmail4">Aadhar Card image</label>
                      <input
                        type="file"
                        class="form-control"
                        id="input_project_img"
                        onChange={(e) => aadharChange(e)}
                      />

                      {/* <button onClick={handleUpload}>click</button> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* SEO section end */}
              <div class="d-flex">
                <div>
                  <button
                    onClick={() => submitKyc()}
                    type="submit"
                    class="btn-red mt-3 border-full"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
// Main function end

// function recall end
export default Brochure;
