import { React, useState } from "react";
// import { login } from './auth';
import { useNavigate, useLocation, Link } from "react-router-dom";
import "./css/login-style.css";
import loginlogo from "./images/logo.jpeg";
import firebase from "firebase";
import "firebase/auth";
import { useAuth } from "./auth";
import { Helmet } from "react-helmet";
import db from "./firebase";

const Login = () => {
  const navigation = useNavigate();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [mobNo, setMobNo] = useState("");
  const TITLE = "cgc-admin";

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   await login(form, navigation);

  // }
  const location = useLocation();

  const redirectPath = location.state?.path || "/";
  const auth = useAuth();
  const handleSubmit = async (e) => {
    e.preventDefault();
    data_get();
    try {
      const res = await firebase
        .auth()
        .signInWithEmailAndPassword(form.email, form.password)
        .then((response) => {
          console.log("login success", response.user.email);
          if (response.user !== "") {
            console.log("call success");
            auth.login(response.user.email);
            db.collection("Vendor_Details")
              .where("vendor_email", "==", form.email)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  // doc.data() is never undefined for query doc snapshots
                  console.log(doc.id, " => ", doc.data());
                  localStorage.setItem(
                    "login_mob_no",
                    doc.data().vendor_mob_no
                  );
                  localStorage.setItem("login_name", doc.data().vendor_id);
                  localStorage.setItem(
                    "login_address",
                    doc.data().shop_address
                  );
                  localStorage.setItem("login", "true");
                  localStorage.setItem("email", form.email);
                  navigation(`/dashboard`, {
                    state: { id: mobNo },
                  });
                });
              })
              .catch((error) => {
                console.log("Error getting documents: ", error);
              });

            // navigation(redirectPath,{state:{id:mobNo} },{ replace: true });
          } else {
            console.log("call failure");
          }
        });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const data_get = () => {
    console.log("function run");
    db.collection("Vendor_Details")
      .where("vendor_email", "==", form.email)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          var data = doc.data();
          console.log(data);
          window.name = data.vendor_mob_no;
          console.log(data.vendor_mob_no);
          setMobNo(data.vendor_mob_no);
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };
  return (
    <div class="h-100 background-img">
      {/* <form onSubmit={handleSubmit} >
			<label for="email">Email</label>
			<input type="text" style={InputFields}
				placeholder="email" id="mail"
			onChange={(e) =>
			setForm({...form, email: e.target.value})} />
			<br/>
			<label for="password">Password</label>
			<input type="password" placeholder="Password"
				style={InputFields}
			onChange={(e) =>
			setForm({...form, password: e.target.value})}/>
			<br/>
			<button type="submit" style={ButtonStyle}>
				Submit
			</button>
			</form>*/}
      <Helmet>
        <title>{"Sailors Choice Vendor Panel"}</title>
      </Helmet>
      <div class="container h-100">
        <div class="row h-100 justify-content-center align-items-center">
          <div class="col-12 col-md-10 col-sm-10 col-xl-8 col-lg-8">
            <div class="row login-card align-items-center">
              <div class="col-md-6">
                <div class="text-center">
                  <img src={loginlogo} width="250px" height="auto" />
                </div>
              </div>
              <div class="col-md-6">
                <h4>Login</h4>
                <form onSubmit={handleSubmit}>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Email address</label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      onChange={(e) =>
                        setForm({ ...form, email: e.target.value })
                      }
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword1">Password</label>
                    <input
                      type="password"
                      class="form-control"
                      id="exampleInputPassword1"
                      onChange={(e) =>
                        setForm({ ...form, password: e.target.value })
                      }
                      placeholder="Password"
                    />
                  </div>
                  <button
                    type="submit"
                    class="btn btn-primary w-100 mt-4 mb-2 h-40 br-full"
                  >
                    Submit
                  </button>
                </form>
                <div class="form-group">
                  <label>Don't have a account?</label>
                  <Link to="/sign-up">
                    <a class="form-control">SignUp</a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
