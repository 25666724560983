// Import section start
// Builders Projects add code in this js file
import { React, useState, useRef, useEffect } from "react";
import db from "./firebase";
import firebase from "firebase";
import $ from "jquery";
import plus from "./images/plus.svg";
import success from "./images/success.svg";
import successpopup from "../images/successpopup.svg";
import editpopup from "./images/editpopup.svg";
import { Modal } from "react-bootstrap";
import Loader from "react-js-loader";
import { Helmet } from "react-helmet";
import warning from "./images/warning.svg";
import { Button } from "react-bootstrap";
import "./css/validate.css";
import Autocomplete from "react-google-autocomplete";
import tooltip from "../images/tooltip.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
// import section end

// Main function start
let array_declare = [];
function About() {
  const [img, setImg] = useState(null);
  const [logoimg, setLogoImg] = useState(null);
  const [projectlogoget, setProjectLogoget] = useState(null);
  const [projectlogoimg, setProjectLogoImg] = useState("");
  const [logoget, setLogoget] = useState("");
  const [proimg, setProimg] = useState("");
  const [multiimg, setMultiImg] = useState([]);
  const [multiimgget, setMultiimgget] = useState([]);
  const [floorImages, setFloorImages] = useState([]);
  const [floorimgget, setMultiiFloorget] = useState([]);
  const [stateSpec, setStateSpec] = useState([
    {
      title: "",
      value: [""],
    },
  ]);
  const [textLocationFetch, setTextLocationFetch] = useState([]);

  const [previewprojectlogoimg, setPreviewprologoimg] = useState(false);
  const [previewlogoimg, setPreviewlogoimg] = useState(false);
  const [previewproimg, setPreviewproimg] = useState(false);
  const [previewfloorimg, setPreviewfloorimg] = useState(false);
  const [previewImg, setPreviewImg] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [modalShow_alert, setModalShow_alert] = useState(false);
  const [modalCheckBox, setCheckBoxalert] = useState(false);

  const [modalShow3, setModalShow3] = useState(false);
  const [projectnameshow, setProjectnameshow] = useState("");

  const [loaderShow, setLoaderShow] = useState(false);

  const [textBoxFetch, setTextBoxFetch] = useState([]);
  const [placeid, setPlaceId] = useState("");
  const [names, setName] = useState("");
  const [vendorDoc, setVendorDoc] = useState("");
  const [info, setInfo] = useState("");
  const [productNumber, setProductNumber] = useState("");

  const TITLE = "cgc-admin";

  var multiArray = [];
  var multiFloorImagesUrl = [];
  var project_logo_img = "";
  var project_img = "";
  var builder_logo = "";

  useEffect(() => {
    date_update();
    fetchData();
  }, [stateSpec]);

  async function fetchData() {
    let vendor_email = await localStorage.getItem("email");

    db.collection("Vendor_Details")
      .where("vendor_email", "==", vendor_email)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          setVendorDoc(doc.id);
          setInfo(doc.data());
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

    db.collection("Product_Details")
      .where(
        "vendor_details.contact_details",
        "==",
        localStorage.getItem("login_mob_no")
      )
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          setProductNumber(querySnapshot.size);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }

  function carosuel_validate() {
    if (multiimgget.length >= 1) {
      document.getElementById("top-carosuel").style.display = "none";
      cover_img_validate();
    } else {
      document.getElementById("top-carosuel").style.display = "block";
      setModalShow_alert(true);
    }
  }
  if (multiimgget.length > 2) {
    document.getElementById("top-carosuel").style.display = "none";
  }
  function cover_img_validate() {
    if (previewproimg === true) {
      document.getElementById("project-cover-img").style.display = "none";
      setModalShow_alert(false);
      submit_data();
    } else {
      document.getElementById("project-cover-img").style.display = "block";
      setModalShow_alert(true);
    }
  }

  if (previewproimg === true) {
    document.getElementById("project-cover-img").style.display = "none";
  }

  const [date, setDate] = useState(false);
  const [year, setYear] = useState(false);
  const [month, setMonth] = useState(false);
  const [today, setToday] = useState(false);
  const [searchInfo, setSearchInfo] = useState([]);

  function date_update() {
    builderNameFetch();
    var year = new Date().getFullYear();
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const d = new Date();
    var month_get = month[d.getMonth()];
    const today = d.getDate();

    setYear(year);
    setDate(today);
    setMonth(month_get);
    setToday(d);
  }

  function builderNameFetch() {
    db.collection("Project_Details")
      .orderBy("timestamp", "desc")
      .get()
      .then((querySnapshot) => {
        // Loop get the data fetching here
        querySnapshot.forEach((element) => {
          var data = element.data();

          data.docId = element.id;
          var builder_name_get = data.builder_details.builder_name;

          setSearchInfo((arr) => [...arr, builder_name_get]);
        });
      });
  }
  async function submit_data() {
    setLoaderShow(true);
    try {
      await sizeGet();
    } catch (error) {}
    await checkFunction();
    await handleUpload();
    await handleUpload_1();
    await handleUpload_multiple();
    const result = await handleUpload_multiple_floor();
    if (result === true) {
      var product_name_get = document.getElementById("input_project").value;
      var product_category_get = document.getElementById(
        "input_property_category"
      ).value;

      var product_description_get =
        document.getElementById("input_overview").value;
      var project_place_get = document.getElementById("input_place").value;
      var price_range_get = document.getElementById("input_price_range").value;
      var product_no_get = document.getElementById("input_rera").value;

      const data = {
        vendor_details: {
          vendor_name: localStorage.getItem("login_name"),
          Address: localStorage.getItem("login_address"),
          contact_details: localStorage.getItem("login_mob_no"),
        },
        city: project_place_get,
        product_highlights: {
          Product_No: product_no_get,
          Price_range: price_range_get,
          product_size: array_declare.length > 0 ? array_declare : "",
        },
        other_details: array_1,
        product_main_img: project_img,
        product_name: product_name_get,
        Specifications: stateSpec,
        top_carosuel_images: multiArray,
        Product_Images: multiFloorImagesUrl,
        product_description: product_description_get,
        date: date,
        year: year,
        month: month,
        timestamp: today,
        // project_location: project_location,
        place_id: placeid,
        location_name: names,
        property_category: product_category_get,
      };
      const res = await db.collection("Product_Details").doc().set(data);
      setLoaderShow(false);
      setModalShow(true);
      setTimeout(function refreshPage() {
        window.location.reload(false);
      }, 4000);
    }
  }

  function myFunction() {
    var table = document.getElementById("myTable");
    var row = table.insertRow(-1);
    var cell1 = row.insertCell(0);
    var cell2 = row.insertCell(1);
    var cell3 = row.insertCell(2);

    cell1.innerHTML = "<input id='bhk_' type='text' class='form-control'/> ";
    cell2.innerHTML = "<input type='text' class='form-control'/>";
    cell3.innerHTML = "<input type='button' value='-' class='removeRow'/>";
  }
  var array_1 = [];
  const checkFunction = () => {
    $("#myTable tr").each(function (i, j) {
      if (i !== 0) {
        var tdArr = [];
        var objdata = {};
        $(this)
          .find("td")
          .each(function (p, q) {
            tdArr.push($(q).find("input").val());
          });

        tdArr.forEach((val, index) => {
          if (index === 0) {
            objdata["unit_type"] = val;
          } else if (index === 1) {
            objdata["size"] = val;
          }
        });
        array_1.push(objdata);
      }
    });
  };

  function sizeGet() {
    array_declare = [];
    // try_();
    var checkedValue = null;
    var obj_ameni = {};
    var inputElements = document.getElementsByClassName("form-check-input");
    for (var i = 0; inputElements[i]; ++i) {
      if (inputElements[i].checked) {
        checkedValue = inputElements[i].value;
        obj_ameni = { content: checkedValue };
        array_declare.push(obj_ameni);
      }
    }
  }

  // var seo_name_updated = () => {
  //   let title_get = document.getElementById("input_project").value;
  //   document.getElementById("input_seo_title").value = title_get;
  // };

  var handleChange = (e) => {
    // seo_name_updated();
    if (e.target.files[0]) {
      setProimg(e.target.files[0]);
      setPreviewproimg(true);
      let reader = new FileReader();
      reader.onloadend = () => {
        //   this.setState({
        //     imagePreviewUrl: reader.result
        //   });
        setImg(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // var project_img;
  const handleUpload = async () => {
    let file = proimg;
    var storage = firebase.storage();
    var storageRef = storage.ref();
    var folder_name = document.getElementById("input_project").value;
    var uploadTask = await storageRef
      .child(folder_name + "/" + file.name)
      .put(file)
      .then((response) => response.ref.getDownloadURL());
    project_img = uploadTask;
  };

  // var logo_img_url;
  const handleUpload_1 = async () => {
    let file = logoget;
    if (file !== "") {
      var storage = firebase.storage();
      var storageRef = storage.ref();
      var folder_name = document.getElementById("input_project").value;
      var uploadTask = await storageRef
        .child(folder_name + "/" + file.name)
        .put(file)
        .then((response) => response.ref.getDownloadURL());
      builder_logo = uploadTask;
    }
  };

  var carosuel_array = [];
  var handleChange_multiple = (e) => {
    setPreviewImg(true);
    let files = e.target.files;
    let ul = [];
    Object.values(files).map((element) => {
      setMultiimgget((arr) => [...arr, element]);
    });
    for (var i = 0; i < files.length; i++) {
      //for multiple files
      // setMultiimgget((arr) => [...arr,e.target.files[i]]);
      var inner_object = e.target.files[i];
      carosuel_array.push(inner_object);
      (function (file) {
        var reader = new FileReader();
        reader.onload = function (e) {
          // get file content
          var text = e.target.result;
          // ul.push({ img: text });
          setMultiImg((arr) => [...arr, text]);
        };
        reader.readAsDataURL(file);
      })(files[i]);
    }
  };

  //handle change for multiple floor plan images
  var handleChange_multiple_floor = (e) => {
    let files = e.target.files;

    let ul = [];
    Object.values(files).map((element) => {
      setMultiiFloorget((arr) => [...arr, element]);
    });
    setPreviewfloorimg(true);
    for (var i = 0; i < files.length; i++) {
      //for multiple files
      (function (file) {
        var reader = new FileReader();
        reader.onload = function (e) {
          // get file content
          var text = e.target.result;
          // ul.push({ img: text });

          setFloorImages((arr) => [...arr, { img: text }]);
        };
        reader.readAsDataURL(file);
      })(files[i]);
    }
  };

  const handleUpload_multiple = async () => {
    var storage = firebase.storage();
    var storageRef = storage.ref();
    var folder_name = document.getElementById("input_project").value;
    const result = multiimgget.map((each_img) => {
      return storageRef
        .child(folder_name + "/top_carosuel_images/" + each_img.name)
        .put(each_img)
        .then((response) => response.ref.getDownloadURL());
    });
    const value = Promise.all(result).then((res) => {
      res.forEach((val) => {
        multiArray.push(val);
      });
      return true;
    });
    return value;
  };

  //upload multiple floor plan images
  const handleUpload_multiple_floor = async () => {
    var storage = firebase.storage();
    var storageRef = storage.ref();
    var folder_name = document.getElementById("input_project").value;
    setProjectnameshow(folder_name);
    const result = floorimgget.map((each_img) => {
      return storageRef
        .child(folder_name + "/floor_plans/" + each_img.name)
        .put(each_img)
        .then((response) => response.ref.getDownloadURL());
    });
    const value = Promise.all(result).then((res) => {
      // var img = {};
      res.forEach((val) => {
        // img["img"] = val;
        multiFloorImagesUrl.push({ img: val });
      });
      return true;
    });
    return value;
  };

  var floorimgref = useRef();
  const removeTop2 = async (item, index) => {
    let array = [...floorImages];
    let array_get = [...floorimgget];
    // const index = array.indexOf(item);
    if (index > -1) {
      array.splice(index, 1); // 2nd parameter means remove one item only
      array_get.splice(index, 1);
    }

    setFloorImages(array);
    setMultiiFloorget(array_get);
  };

  $(document).on("click", ".removeRow", function () {
    $(this).closest("tr").remove();
  });

  $(document).on("click", ".removespecRow", function () {
    $(this).closest("tr").remove();
  });

  const removeTop = async (item) => {
    let array = [...multiimg];
    let array_get_f = [...multiimgget];
    const index = array.indexOf(item);
    const index2 = array_get_f.indexOf(item);
    if (index > -1) {
      array_get_f.splice(index2, 1);
      array.splice(index, 1);
      // 2nd parameter means remove one item only
    }

    setMultiImg(array);
    setMultiimgget(array_get_f);
  };

  const ref_Project_Cover = useRef();

  const removeProjectCoverImg = async () => {
    setPreviewproimg(false);
    setImg(null);
    ref_Project_Cover.current.value = "";
  };

  // popup success start
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body class="text-center p-5">
          <img src={successpopup} style={{ width: "65px", height: "65px" }} />
          <h4 class="text-center mt-4">{projectnameshow}</h4>
          <p class="text-center">Your product was added successfully</p>
          <Button onClick={props.onHide} className="btn-popup-sucess mt-3">
            Continue
          </Button>
        </Modal.Body>
      </Modal>
    );
  }

  // popup delete start
  function MyVerticallyCenteredModal_alert(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body class="text-center p-5">
          {/* <img src={successpopup} style={{ width: "65px", height: "65px", }} /> */}
          <h4 class="text-center mt-4">cgc-admin.web.app says</h4>
          <p class="text-center">
            some require fields are missing please check
          </p>
          <Button onClick={props.onHide} className="btn-popup-edit mt-3">
            Continue
          </Button>
        </Modal.Body>
      </Modal>
    );
  }

  function text_box_click() {
    var about_check;
    document.getElementById("no-results").style.display = "block";
    document.getElementById("results").style.display = "none";

    var builder_name = document.getElementById("input_builder_name").value;

    db.collection("Project_Details")
      .where("builder_details.builder_name", "==", builder_name)
      .get()
      .then((querySnapshot) => {
        // Loop get the data fetching here
        querySnapshot.forEach((element) => {
          var data = element.data();
          data.docId = element.id;
          setTextBoxFetch((arr) => [...arr, data]);

          document.getElementById("input_about").value = data.about;
          setLogoImg(data.builder_details.builder_logo);
          setPreviewlogoimg(true);
          about_check = data.about;

          if (about_check === undefined) {
            document.getElementById("no-results").style.display = "block";
            document.getElementById("results").style.display = "none";
          } else {
            document.getElementById("results").style.display = "block";
            document.getElementById("no-results").style.display = "none ";
          }
        });
      });
  }

  function addPointValue(index) {
    const row = [...stateSpec];
    row[index].value.push("");
    setStateSpec(row);
  }

  function addSpeRow() {
    const row = [...stateSpec];
    const rowsInput = {
      title: "",
      value: [""],
    };
    row.push(rowsInput);
    setStateSpec(row);
  }

  function deleteSpeRow(index) {
    const row = [...stateSpec];
    row.splice(index, 1);
    setStateSpec(row);
  }

  function deleteSpeCntRow(index, index1) {
    const row = [...stateSpec];
    row[index].value.splice(index1, 1);
    setStateSpec(row);
  }

  // Auto complete Search section start
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionIndex, setSuggestionIndex] = useState(0);
  const [suggestionsActive, setSuggestionsActive] = useState(false);
  const [value, setValue] = useState("");
  const [propertyCategory, setPropertyCategory] = useState("");

  const handleChange_input = (e) => {
    const query = e.target.value.toLowerCase();
    setValue(query);
    if (query.length < 1) {
      document.getElementById("autocom-box").style.display = "none";
    }
    if (query.length > 1) {
      document.getElementById("autocom-box").style.display = "block";
      const filterSuggestions = searchInfo.filter(
        (suggestion) => suggestion.toLowerCase().indexOf(query) > -1
      );
      setSuggestions(filterSuggestions);
      setSuggestionsActive(true);
    } else {
      setSuggestionsActive(false);
    }
  };

  const handleClick = (e) => {
    document.getElementById("autocom-box").style.display = "none";
    setSuggestions([]);
    setValue(e.target.innerText);
    setSuggestionsActive(false);
  };

  const handleKeyDown = (e) => {
    // UP ARROW
    if (e.keyCode === 38) {
      if (suggestionIndex === 0) {
        // document.getElementById("autocom-box").style.display = "none"
        return;
      }
      setSuggestionIndex(suggestionIndex - 1);
    }
    // DOWN ARROW
    else if (e.keyCode === 40) {
      if (suggestionIndex - 1 === suggestions.length) {
        return;
      }
      setSuggestionIndex(suggestionIndex + 1);
    }
    // ENTER
    else if (e.keyCode === 13) {
      setValue(suggestions[suggestionIndex]);
      setSuggestionIndex(0);
      setSuggestionsActive(false);
    }
  };

  const Suggestions = () => {
    return (
      <ul className="suggestions">
        {suggestions.map((suggestion, index) => {
          return (
            <li
              className={index === suggestionIndex ? "active" : ""}
              key={index}
              onClick={handleClick}
            >
              {suggestion}
            </li>
          );
        })}
      </ul>
    );
  };
  // Auto complete Search section end

  function MyVerticallyCheckBoxalert(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body class="text-center p-5">
          {/* <img src={successpopup} style={{ width: "65px", height: "65px", }} /> */}
          <h4 class="text-center mt-4">cgc-admin.web.app says</h4>
          <p class="text-center">allowed only 2</p>
          <Button onClick={props.onHide} className="btn-popup-edit mt-3">
            Continue
          </Button>
        </Modal.Body>
      </Modal>
    );
  }

  // end

  return (
    <div>
      <Helmet>
        <title>{"Vendor Add product section"}</title>
      </Helmet>

      <div class="container mt-5 mb-4">
        <div class="row">
          <div class="col-md-12">
            <h4>Add Product</h4>
          </div>
        </div>
      </div>
      <div>
        {info.vendor_status === 2 ? (
          <div className="text-center">
            Your Kyc status is pending... so please wait...
          </div>
        ) : info.vendor_status === 3 ? (
          <div className="text-center">
            Your Kyc status was rejected... so please contact admin...
          </div>
        ) : info.vendor_status === 0 ? (
          <div className="text-center">
            Welcome, New vendor to thesailorschoice.com... so please submit
            kyc...
          </div>
        ) : (
          <div className="container pb-5">
            <div class="card mb-5">
              <div class="card-header">
                <h5 class="m-0">Product slider images</h5>
              </div>
              <div class="card-body mb-4">
                <div class="row">
                  <div class="form-group col-md-6">
                    <div class="upload-relative">
                      <div>
                        <label class="form-label" for="customFile">
                          Add multiple images (Must one images add)
                        </label>
                        <input
                          type="file"
                          class="form-control"
                          id="customFile"
                          onChange={handleChange_multiple}
                          multiple
                        />
                        <p
                          style={{ display: "none" }}
                          id="top-carosuel"
                          className="validate_hint text-red m-0"
                        >
                          Must upload on one image
                        </p>
                      </div>
                      {multiimgget.length !== 0 ? (
                        <p class="m-0 upload-absolute">
                          {multiimgget.length} files
                        </p>
                      ) : (
                        <p class="m-0 upload-absolute">No files choosen</p>
                      )}
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="d-flex mt-2">
                      {multiimg.map((val, index) => (
                        <div class="preview-img-relative">
                          <img src={val} class="preview-img" />
                          <div class="preview-img-absolute">
                            <button
                              onClick={() => removeTop(val)}
                              class="btn-remove"
                            >
                              x
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mb-5">
              <div class="card-header">
                <h5 class="m-0">Product details</h5>
              </div>
              <div class="card-body mb-4">
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="inputEmail4">Product Name</label>
                    <input
                      type="email"
                      class="form-control"
                      id="input_project"
                    />
                    <p
                      style={{ display: "none" }}
                      className="validate_hint text-red m-0"
                    >
                      Enter a project name must
                    </p>
                  </div>
                  {/* <div class="form-group col-md-6">
                    <label for="inputPassword4">Product Delivery stage</label>
                    <select id="input_status" class="form-control">
                      <option value="" selected>
                        Choose...
                      </option>
                      <option>Paid</option>
                      <option>Free Delivery</option>
                    </select>
                    <p style={{ display: "none" }} className="validate_hint">
                      Enter a project status must
                    </p>
                  </div> */}

                  <div class="form-group col-md-6">
                    <label for="inputPassword4">Product Main image</label>
                    <input
                      type="file"
                      class="form-control"
                      onChange={handleChange}
                      ref={ref_Project_Cover}
                    />
                    <p
                      style={{ display: "none" }}
                      className="validate_hint text-red m-0"
                      id="project-cover-img"
                    >
                      Add must project cover image
                    </p>
                    <div>
                      {previewproimg ? (
                        <div class="preview-img-relative">
                          <img class="preview-img" src={img} />
                          <div class="preview-img-absolute">
                            <button
                              onClick={() => removeProjectCoverImg()}
                              class="btn-remove"
                            >
                              x
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputPassword4">Product Category</label>
                    <select
                      onChange={(e) => setPropertyCategory(e.target.value)}
                      id="input_property_category"
                      class="form-control"
                    >
                      <option value="" selected>
                        Choose...
                      </option>
                      <option value="Fashion">Fashion</option>
                      <option value="Electronics">Electronics</option>
                      <option value="Food & Drinks">Food & Drinks</option>
                      <option value="Beauty and health care">
                        Beauty and health care
                      </option>
                      <option value="Domestic Appliances">
                        Domestic Appliances
                      </option>
                      <option value="Sports and gym">Sports and gym</option>
                      <option value="Watches & Jewellery">
                        Watches & Jewellery
                      </option>
                      <option value="Lifestyle & outfits">
                        Lifestyle & outfits
                      </option>
                      <option value="Stationaries">Stationaries</option>
                      <option value="Ship orders">Ship orders</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mb-5">
              <div class="card-header">
                <h5 class="m-0">Product Configuration</h5>
              </div>
              <div class="card-body mb-4">
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="inputPassword4">Product No</label>
                    <input
                      type="text"
                      class="form-control"
                      id="input_rera"
                      value={productNumber}
                      disabled
                    />
                    <p style={{ display: "none" }} className="validate_hint">
                      Enter a RERA NO must
                    </p>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputPassword4">Price Range</label>
                    <input
                      type="text"
                      class="form-control"
                      id="input_price_range"
                    />
                    <p style={{ display: "none" }} className="validate_hint">
                      Enter a Development size must
                    </p>
                  </div>
                </div>
              </div>
              <div class="card-body mb-4 mt-3">
                {propertyCategory === "Fashion" ? (
                  <>
                    <label for="inputPassword4">Product Sizes</label>
                    <div class="d-flex">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox0"
                          value="S"
                          // onClick={() => check_box_alert()}
                        />
                        <h6 class="text-center">
                          <label
                            class="form-check-label p-0"
                            for="inlineCheckbox1"
                          >
                            S
                          </label>
                        </h6>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox0"
                          value="M"
                          // onClick={() => check_box_alert()}
                        />
                        <h6 class="text-center">
                          <label
                            class="form-check-label p-0"
                            for="inlineCheckbox1"
                          >
                            M
                          </label>
                        </h6>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox0"
                          value="L"
                          // onClick={() => check_box_alert()}
                        />
                        <h6 class="text-center">
                          <label
                            class="form-check-label p-0"
                            for="inlineCheckbox1"
                          >
                            L
                          </label>
                        </h6>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox0"
                          value="XL"
                          // onClick={() => check_box_alert()}
                        />
                        <h6 class="text-center">
                          <label
                            class="form-check-label p-0"
                            for="inlineCheckbox1"
                          >
                            XL
                          </label>
                        </h6>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox0"
                          value="XXL"
                          // onClick={() => check_box_alert()}
                        />
                        <h6 class="text-center">
                          <label
                            class="form-check-label p-0"
                            for="inlineCheckbox1"
                          >
                            XXL
                          </label>
                        </h6>
                      </div>
                    </div>
                  </>
                ) : propertyCategory === "Lifestyle & outfits" ? (
                  <>
                    <label for="inputPassword4">Product Sizes</label>
                    <div class="d-flex">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox0"
                          value="6"
                          // onClick={() => check_box_alert()}
                        />
                        <h6 class="text-center">
                          <label
                            class="form-check-label p-0"
                            for="inlineCheckbox1"
                          >
                            6
                          </label>
                        </h6>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox0"
                          value="7"
                          // onClick={() => check_box_alert()}
                        />
                        <h6 class="text-center">
                          <label
                            class="form-check-label p-0"
                            for="inlineCheckbox1"
                          >
                            7
                          </label>
                        </h6>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox0"
                          value="8"
                          // onClick={() => check_box_alert()}
                        />
                        <h6 class="text-center">
                          <label
                            class="form-check-label p-0"
                            for="inlineCheckbox1"
                          >
                            8
                          </label>
                        </h6>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox0"
                          value="9"
                          // onClick={() => check_box_alert()}
                        />
                        <h6 class="text-center">
                          <label
                            class="form-check-label p-0"
                            for="inlineCheckbox1"
                          >
                            9
                          </label>
                        </h6>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox0"
                          value="10"
                          // onClick={() => check_box_alert()}
                        />
                        <h6 class="text-center">
                          <label
                            class="form-check-label p-0"
                            for="inlineCheckbox1"
                          >
                            10
                          </label>
                        </h6>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            <div class="card mb-5">
              <div class="card-header">
                <h5 class="m-0">Product Description</h5>
              </div>
              <div class="card-body mb-4">
                <div class="row">
                  <div class="form-group col-md-12">
                    <label for="inputCity">Description</label>
                    <textarea
                      type="text"
                      class="form-control h-200"
                      id="input_overview"
                    ></textarea>
                  </div>
                  <p style={{ display: "none" }} className="validate_hint">
                    Enter a Project Overview must
                  </p>
                </div>
              </div>
            </div>

            <div class="card mb-5">
              <div class="card-header">
                <h5 class="m-0">Product Specification</h5>
              </div>
              <div class="card-body mb-4">
                <div class="row">
                  <div id="unit_section">
                    <table class="table" id="myTable" data="regMultipleTable">
                      <tr>
                        <th id="bhk">Key</th>
                        <th id="type">Value</th>
                      </tr>
                      <tr>
                        <td>
                          <input id="bhk_" class="form-control" type="text" />
                        </td>
                        <td>
                          <input type="text" class="form-control" />
                        </td>
                        <td>
                          <input type="button" value="-" class="removeRow" />
                        </td>
                      </tr>
                    </table>
                    <button
                      class="btn-red mt-3 border-full btn-add-row"
                      type="button"
                      onClick={myFunction}
                    >
                      <img
                        src={plus}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginTop: "2px",
                        }}
                      />
                      <span style={{ marginLeft: "10px" }}>Add more row</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mb-5">
              <div class="card-header">
                <h5 class="m-0">Same Product Different Color</h5>
              </div>
              <div class="card-body mb-4">
                <div class="row">
                  <div class="form-group col-md-6">
                    <div class="upload-relative">
                      <div>
                        <label class="form-label" for="customFile">
                          Add multiple images
                        </label>
                        <input
                          type="file"
                          ref={floorimgref}
                          class="form-control"
                          id="floor-img"
                          onChange={handleChange_multiple_floor}
                          multiple
                        />
                      </div>
                      {floorimgget.length !== 0 ? (
                        <p class="m-0 upload-absolute">
                          {floorimgget.length} files
                        </p>
                      ) : (
                        <p class="m-0 upload-absolute">No files choosen</p>
                      )}
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="d-flex mt-2">
                      {floorImages.map((val, index) => (
                        <div class="preview-img-relative">
                          <img src={val.img} class="preview-img" />
                          <div class="preview-img-absolute">
                            <button
                              onClick={() => removeTop2(val.img, index)}
                              class="btn-remove"
                            >
                              x
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div class="card mb-5">
              <div class="card-header">
                <h5 class="m-0">Product Specification</h5>
                <p className="mb-0 mt-2 text-grey">
                  Note : Enter Project specification points are separated by
                  comma
                </p>
              </div>
              <div class="card-body mb-4">
                <div class="row">
                  <div id="unit_section">
                    <div class="res-table">
                      <table
                        class="table"
                        id="specTable2"
                        data="regMultipleTable"
                      >
                        <tr>
                          <th id="spec_title2">Specification Title</th>
                          <th id="spec_points2">Specification Points</th>
                          <th class="add-delete-table-title">Add points</th>
                          <th class="add-delete-table-title">Delete row</th>
                        </tr>
                        {stateSpec.map((item, index) => {
                          return (
                            <tr>
                              <td>
                                <input
                                  class="form-control"
                                  style={{ margin: 10 }}
                                  type="text"
                                  value={item.title}
                                  onChange={(e) => {
                                    let array = [...stateSpec];
                                    array[index].title = e.target.value;
                                    setStateSpec(array);
                                  }}
                                />
                              </td>
                              <td>
                                <table class="spec-points-table">
                                  {item.value.map((item1, index1) => {
                                    return (
                                      <tr>
                                        <td>
                                          <textarea
                                            class="form-control"
                                            type="text"
                                            onChange={(e) => {
                                              let array = [...stateSpec];
                                              array[index].value[index1] =
                                                e.target.value;
                                              setStateSpec(array);
                                            }}
                                          >
                                            {item1}
                                          </textarea>
                                        </td>
                                        <td>
                                          <button
                                            class="remove-btn-small"
                                            onClick={() =>
                                              deleteSpeCntRow(index, index1)
                                            }
                                          >
                                            -
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </table>
                              </td>
                              <td class="text-center">
                                <button
                                  class="add-btn-small"
                                  onClick={() => addPointValue(index)}
                                >
                                  +
                                </button>
                              </td>
                              <td class="text-center">
                                <button
                                  class="remove-btn-small"
                                  onClick={() => deleteSpeRow(index)}
                                >
                                  -
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                      <button
                        class="btn-red mt-3 border-full btn-add-row"
                        type="button"
                        onClick={() => addSpeRow()}
                      >
                        <img
                          src={plus}
                          style={{
                            width: "20px",
                            height: "20px",
                            marginTop: "4px",
                          }}
                        />
                        <span style={{ marginLeft: "10px" }}>
                          {" "}
                          Add more row{" "}
                        </span>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <BindSpe2Table /> */}

            <div class="card mb-4">
              <div class="card-header">
                <h5 class="m-0">Delivery details</h5>
              </div>
              <div class="card-body mb-4">
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="inputState">Delivery port</label>
                    <select id="input_place" class="form-control">
                      <option value="" selected>
                        Choose...
                      </option>
                      <option>Chennai-Ennore</option>
                      <option>Tutucorin</option>
                    </select>
                    <p style={{ display: "none" }} className="validate_hint">
                      choose a project place must
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <>
              <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </>
            <>
              <MyVerticallyCenteredModal_alert
                show={modalShow_alert}
                onHide={() => setModalShow_alert(false)}
              />
            </>
            <>
              <MyVerticallyCheckBoxalert
                show={modalCheckBox}
                onHide={() => setCheckBoxalert(false)}
              />
            </>
            <div class="d-flex">
              <div>
                <button
                  onClick={() => carosuel_validate()}
                  type="submit"
                  class="btn-red mt-3 border-full"
                >
                  Submit
                </button>
              </div>
              <div style={{ marginLeft: "32px" }}>
                {loaderShow === true ? (
                  <div className={"item"}>
                    <Loader
                      type="spinner-cub"
                      bgColor={"red"}
                      title={"please wait"}
                      color={"red"}
                      size={30}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

// Main function end
export default About;
