import { React, useState, useEffect } from "react";
import db from "./firebase";
import { useNavigate } from "react-router-dom";
import "./css/styles.css";
import phone from "./images/phone.svg";
import trash from "./images/trash.svg";
import edit from "./images/edit.svg";
import { Button } from "react-bootstrap";
import deletepopup from "../images/deletepopup.svg";
import { Modal } from "react-bootstrap";
import editpopup from "../images/checkpopup.svg";
import norecord from "./images/no record found.svg";
import { Helmet } from "react-helmet";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

const columns = [
  { id: "no", label: "S.No", minWidth: 100 },
  { id: "img", label: "Image", minWidth: 50 },
  {
    id: "productName",
    label: "Product Name",
    minWidth: 80,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "price",
    label: "Product price",
    minWidth: 80,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "edit",
    label: "Edit",
    minWidth: 80,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "delete",
    label: "Delete",
    minWidth: 80,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
];

// function createData(name, code, population, size) {
//   const density = population / size;
//   return { name, code, population, size };
// }

let rows = [];
let array = [];
function About() {
  const [info, setInfo] = useState([]);
  const [fullinfo, setFullinfo] = useState([]);
  const [buildername, setBuilderName] = useState([]);
  const navigate = useNavigate();

  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);

  useEffect(() => {
    console.log("global variable projects page", window.name);
    console.log("global variable projects page", typeof window.name);
    Fetchdata();
  }, []);

  const Fetchdata = () => {
    //   db.collection("Product_Details").where("vendor_details.contact_details","==",window.name).get().then((querySnapshot) => {

    //     // Loop get the data fetching here
    //     querySnapshot.forEach(element => {
    //       var data = element.data();
    //       console.log(data)

    //       data.docId = element.id;
    //       setInfo(arr => [...arr, data]);
    //       var builder_name_assign = data.builder_details.builder_name
    //       setBuilderName(arr => [...arr, builder_name_assign])
    //       setFullinfo(arr=>[...arr,data])

    //     });
    //   })
    console.log(localStorage.getItem("login_mob_no"), "mobnum");
    array = [];
    rows = [];
    db.collection("Product_Details")
      .where(
        "vendor_details.contact_details",
        "==",
        localStorage.getItem("login_mob_no")
      )
      .get()
      .then((querySnapshot) => {
        let count = 0;
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots\
          let data = doc.data();
          // console.log(doc.id, " => ", doc.data());
          data.docId = doc.id;
          // setInfo(arr => [...arr, data]);
          array.push(data);
          setFullinfo((arr) => [...arr, data]);
          let obj = {
            no: count + 1,
            img: <img src={data.product_main_img} style={{ height: "50px" }} />,
            productName: data.product_name,
            price: data.product_highlights.Price_range,
            delete: (
              <img
                src={deletepopup}
                onClick={() => setModalShow2({ popup: true, id: data.docId })}
                style={{ height: "30px" }}
              />
            ),
            edit: (
              <img
                src={editpopup}
                onClick={() => setModalShow3({ popup: true, id: data.docId })}
                style={{ height: "30px" }}
              />
            ),
          };
          rows.push(obj);
          count += 1;
          // var builder_name_assign = data.vendor_details.vendor_name;
          // setBuilderName((arr) => [...arr, builder_name_assign]);
        });
      })
      .catch((error) => {
        setInfo(array);

        console.log("Error getting documents: ", error);
      })
      .then(() => {
        console.log(array);
        setInfo(array);
      });
  };

  const [city, setCity] = useState("");
  const [area, setArea] = useState("");
  const [name, setName] = useState("");

  const handleChangeCity = (event) => {
    setCity(event.target.value);
  };
  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  const handleChangeArea = (event) => {
    setArea(event.target.value);
  };
  function filter_search() {
    if (area === "" && city === "") {
      db.collection("Project_Details")
        .where("builder_details.builder_name", "==", name)
        .get()
        .then((querySnapshot) => {
          let SearchLists = [];
          // Loop get the data fetching here
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;
            SearchLists.push(data);
          });
          setInfo(SearchLists);
        });
    } else if (name === "" && city === "") {
      db.collection("Project_Details")
        .where("builder_area", "==", area)
        .get()
        .then((querySnapshot) => {
          let SearchLists = [];
          // Loop get the data fetching here
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;
            SearchLists.push(data);
          });
          setInfo(SearchLists);
        });
    } else if (name === "" && area === "") {
      db.collection("Project_Details")
        .where("city", "==", city)
        .get()
        .then((querySnapshot) => {
          let SearchLists = [];
          // Loop get the data fetching here
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;
            SearchLists.push(data);
          });
          setInfo(SearchLists);
        });
    } else if (city === "") {
      db.collection("Project_Details")
        .where("builder_details.builder_name", "==", name)
        .where("builder_area", "==", area)
        .get()
        .then((querySnapshot) => {
          let SearchLists = [];
          // Loop get the data fetching here
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;
            SearchLists.push(data);
          });
          setInfo(SearchLists);
        });
    } else if (area === "") {
      db.collection("Project_Details")
        .where("city", "==", city)
        .where("builder_details", "==", { builder_name: name })
        .get()
        .then((querySnapshot) => {
          let SearchLists = [];
          // Loop get the data fetching here
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;
            SearchLists.push(data);
          });
          setInfo(SearchLists);
        });
    } else if (name === "") {
      db.collection("Project_Details")
        .where("city", "==", city)
        .where("builder_area", "==", area)
        .get()
        .then((querySnapshot) => {
          let SearchLists = [];
          // Loop get the data fetching here
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;
            SearchLists.push(data);
          });
          setInfo(SearchLists);
        });
    } else {
      db.collection("Project_Details")
        .where("city", "==", city)
        .where("builder_details", "==", { builder_name: name })
        .where("builder_area", "==", area)
        .get()
        .then((querySnapshot) => {
          let SearchLists = [];
          querySnapshot.forEach((element) => {
            var data = element.data();

            data.docId = element.id;
            SearchLists.push(data);
          });
          setInfo(SearchLists);
        });
    }
  }

  const groupBy = (key) => (array) =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

  const groupBycity = groupBy("city");
  var split_group_by_builder_city = groupBycity(fullinfo);

  const groupByarea = groupBy("builder_area");
  var split_group_by_builder_area = groupByarea(fullinfo);

  const groupBybuildername = groupBy("builder_area");
  var split_group_by_builder_name = groupBy(buildername);
  var uniquebuildername = [...new Set(buildername)];
  async function delete_func(doc_id_get) {
    // alert(doc_id_get);
    // await deleteDoc(doc(db, "User_feedback", doc_id_get));
    const res = await db.collection("Product_Details").doc(doc_id_get).delete();
    // Fetchdata()
    // alert("delete successfully")
    setModalShow2(false);
    // navigate('/dashboard')
    // refreshPage();
    window.location.reload(false);
  }

  function edit_function(value) {
    navigate({
      pathname: "/edit_projects",
      search: value,
    });
    // alert(value);
  }
  function MyVerticallyCenteredModal2(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body class="text-center p-5">
          <img src={deletepopup} style={{ width: "65px", height: "65px" }} />
          <h4 class="text-center mt-4">Delete Product</h4>
          <p class="text-center">
            Are you sure, you want to delete this product
          </p>
          <Button onClick={props.onHide} className="btn-popup-cancel mt-3">
            Cancel
          </Button>
          <Button
            onClick={() => delete_func(props.doc_id_pass)}
            className="btn-popup-delete mt-3"
          >
            Delete
          </Button>
        </Modal.Body>
      </Modal>
    );
  }

  // popup edit start
  function MyVerticallyCenteredModal3(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body class="text-center p-5">
          <img src={editpopup} style={{ width: "65px", height: "65px" }} />
          <h4 class="text-center mt-4">Edit product</h4>
          <p class="text-center">Are you sure, you want to edit this product</p>
          <Button onClick={props.onHide} className="btn-popup-cancel mt-3">
            Cancel
          </Button>
          <Button
            onClick={() => edit_function(props.doc_id_pass)}
            className="btn-popup-edit mt-3"
          >
            Edit
          </Button>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <div>
      <Helmet>
        <title>Vendor Products List</title>
      </Helmet>
      <div class="container mt-5">
        <div class="row">
          <div class="col-md-12">
            <h4>Products List</h4>
          </div>
        </div>
      </div>
      <div class="pt-4 pb-5">
        <div className="container-fluid">
          <div className="container">
            {/* <div className="row filter-ui">
              <div className="col-md-3">
                <p className="mb-2">Product</p>
                <select onChange={handleChangeCity}>
                  <option value="">Select Product</option>

                  {Object.keys(split_group_by_builder_city).map(
                    (assign_city) => (
                      <option>{assign_city}</option>
                    )
                  )}
                </select>
              </div>
              <div className="col-md-3">
                <p className="mb-2">Select vendor</p>
                <select onChange={handleChangeName}>
                  <option value="">Select Vendor</option>

                  {uniquebuildername.map((builder_name) => (
                    <option>{builder_name}</option>
                  ))}
                </select>
              </div>
              <div className="col-md-3">
                <p className="mb-2">Select number</p>
                <select onChange={handleChangeArea}>
                  <option value="">Select number</option>
                  {Object.keys(split_group_by_builder_area).map((area_data) => (
                    <option>{area_data}</option>
                  ))}
                </select>
              </div>
              <div className="col-md-3">
                <button
                  onClick={() => filter_search()}
                  type="Search"
                  className="search-filter-btn"
                >
                  Search
                </button>
              </div>
            </div> */}
          </div>
        </div>

        {info.map((data, querySnapshot) => (
          <>
            {querySnapshot === 0 ? (
              <>
                <Homepage
                  city={data.city}
                  product_modal={data.product_modal}
                  // mobile_number={data.vendor_details.contact_details}
                  vendor_address={data.city}
                  product_name={data.product_name}
                  vendor_name={data.vendor_id}
                  doc_id={data.docId}
                  date_get={data.date}
                  month_get={data.month}
                  year_get={data.year}
                  full_data={data}
                />
                <MyVerticallyCenteredModal3
                  show={modalShow3.popup}
                  onHide={() => setModalShow3(false)}
                  doc_id_pass={modalShow3.id}
                />
                <MyVerticallyCenteredModal2
                  show={modalShow2.popup}
                  onHide={() => setModalShow2(false)}
                  doc_id_pass={modalShow2.id}
                />
              </>
            ) : null}
          </>
        ))}
        {/* {<footer/>} */}
        <div className="row text-center">
          <div>
            {info.length === 0 ? (
              <img src={norecord} className="norecord" />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
const Homepage = ({
  city,
  product_modal,
  mobile_number,
  vendor_address,
  product_name,
  doc_id,
  vendor_name,
  year_get,
  month_get,
  date_get,
  full_data,
}) => {
  const navigate = useNavigate();

  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function refreshPage() {
    window.location.reload(false);
  }

  async function delete_func(doc_id_get) {
    // alert(doc_id_get);
    // await deleteDoc(doc(db, "User_feedback", doc_id_get));
    const res = await db.collection("Product_Details").doc(doc_id_get).delete();
    // Fetchdata()
    // alert("delete successfully")
    setModalShow2(false);
    // navigate('/dashboard')
    refreshPage();
  }

  function edit_function(value) {
    navigate({
      pathname: "/edit_projects",
      search: value,
    });
    // alert(value);
  }
  // popup delete start
  function MyVerticallyCenteredModal2(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body class="text-center p-5">
          <img src={deletepopup} style={{ width: "65px", height: "65px" }} />
          <h4 class="text-center mt-4">{product_name}</h4>
          <p class="text-center">
            Are you sure, you want to delete this product
          </p>
          <Button onClick={props.onHide} className="btn-popup-cancel mt-3">
            Cancel
          </Button>
          <Button
            onClick={() => delete_func(doc_id)}
            className="btn-popup-delete mt-3"
          >
            Delete
          </Button>
        </Modal.Body>
      </Modal>
    );
  }

  // popup edit start
  function MyVerticallyCenteredModal3(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body class="text-center p-5">
          <img src={editpopup} style={{ width: "65px", height: "65px" }} />
          <h4 class="text-center mt-4">{product_name}</h4>
          <p class="text-center">Are you sure, you want to edit this product</p>
          <Button onClick={props.onHide} className="btn-popup-cancel mt-3">
            Cancel
          </Button>
          <Button
            onClick={() => edit_function(doc_id)}
            className="btn-popup-edit mt-3"
          >
            Edit
          </Button>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    // {course.map((value) => (

    <div class="container">
      <div class="card mt-5">
        <div class="card-header">
          <div class="row">
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            {/* <div class="col-md-6">
              <h6 class="m-0">
                {product_name}
                <span class="color-grey">
                  <span style={{ marginLeft: "10px" }}>&#60;</span>
                  {product_modal}
                  <span>&#62;</span>
                </span>
              </h6>
            </div>
            <div class="col-md-6">
              <div class="d-flex justify-content-end">
                <p class="contact-no d-flex align-items-center">
                  <img src={phone} style={{ width: "16px", height: "16px" }} />
                  <span class="pl-2" style={{ marginLeft: "7px" }}>
                    {/* {full_data.vendor_details.contact_details} 
                  </span>
                </p>

                <>
                  <Button
                    className="btn-edit"
                    variant="primary"
                    onClick={() => setModalShow3(true)}
                  >
                    <img src={edit} style={{ width: "20px", height: "20px" }} />
                  </Button>

                  <MyVerticallyCenteredModal3
                    show={modalShow3}
                    onHide={() => setModalShow3(false)}
                  />
                </>
                <>
                  <Button
                    className="btn-delete"
                    variant="primary"
                    onClick={() => setModalShow2(true)}
                  >
                    <img
                      src={trash}
                      style={{ width: "20px", height: "20px" }}
                    />
                  </Button>

                  <MyVerticallyCenteredModal2
                    show={modalShow2}
                    onHide={() => setModalShow2(false)}
                  />
                </>
              </div>
            </div>*/}
          </div>
        </div>
        {/* <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <h5 class="card-title">{product_name}</h5>
              <p class="card-text m-0">
                Product price: {full_data.product_highlights.Price_range}
              </p>
              <p class="card-text m-0"> Address: {vendor_address}</p>
            </div>
            <div class="col-md-4">
              <h5 class="card-title">Product Image</h5>
              <img
                class="card-text m-0"
                style={{ height: "50px" }}
                src={full_data.product_main_img}
              />
            </div>
            <div class="col-md-2">
              <p class="card-text text-end m-0">
                {date_get}/{month_get}/{year_get}
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
    // ))}
  );
};
export default About;
