import React, { useState, createContext, useContext, useEffect } from "react";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);


  const login = (user) => {
    setUser(user);
  };
  const logout = () => {
    setUser(null);
  };
  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};


// import firebase from 'firebase';
// import "firebase/auth"
// // import {signOut} from "firebase/auth"
// import {auth} from './firebase'

// // function login_(){
// //     const navigate = useNavigate();
// // }
// export const register = async({email, password})=>{
    
// 	const resp = await firebase.auth()
// 	.createUserWithEmailAndPassword(email, password);
// 	return resp.user;
// }


// export const login = async({email, password},navigation)=>{
    
//     try{
        
// 	const res = await firebase.auth()
// 	.signInWithEmailAndPassword(email, password).then((response)=>{
//         console.log("login success",response)

//          if(response.user!==""){
//                 console.log("call success")
//                 navigation('/dashboard');
//     }else{
//         console.log("call failure")
//     }
//     })

// 	// return res.user;
//     // console.log(res.user);
//     // if(res.user!==""){

//     // }
    
//     }catch (err) {
//         console.error(err);
//         alert(err.message);
//       }
// }

// // export default login_;