import { React, useState, useEffect, useRef } from "react";
import db from "./firebase";
import firebase from "firebase";
import $ from "jquery";
import { useNavigate, useLocation } from "react-router-dom";
import plus from "./images/plus.svg";
import successpopup from "../images/successpopup.svg";
import { Modal } from "react-bootstrap";
import success from "./images/success.svg";
import { Helmet } from "react-helmet";
import Loader from "react-js-loader";
import { Button } from "react-bootstrap";
import "./css/styles.css";
import "./css/update-style.css";
import Autocomplete from "react-google-autocomplete";
import tooltip from "../images/tooltip.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function About() {
  const [info, setInfo] = useState([]);
  const [projectSpecs, setProjectSpecs] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  let location = useLocation();
  const TITLE = "cgc-admin";

  useEffect(() => {
    console.log("global variable project Edit page", window.name);
    Fetchdata();

    return () => {};
  }, []);

  useEffect(() => {
    info["Specifications"] = projectSpecs;
    setInfo(info);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectSpecs, isLoading]);

  const Fetchdata = () => {
    var doc_id_get = location.search;
    db.collection("Product_Details")
      .doc(doc_id_get.split("?")[1])
      .get()
      .then((querySnapshot) => {
        // Loop through the data and store

        var data = querySnapshot.data();
        setInfo((arr) => [...arr, data]);
      });
  };
  const onSpecificationDetailChanged = (value) => {
    setIsLoading(true);
    setProjectSpecs({});
    setProjectSpecs(value);
  };
  // Display the result on the page
  return (
    <div>
      <Helmet>
        <title>{"Vendor Products Edit Page"}</title>
      </Helmet>
      {info.map((data, querySnapshot) => (
        <Frame
          description={data.product_description}
          address={data.vendor_details.Address}
          top_carosuel_image={data.top_carosuel_images}
          product_modal_get={data.product_modal}
          vendor_details={data.vendor_details}
          vendor_highlights={data.product_highlights}
          product_different_color={data.Product_Images}
          other_details={data.other_details}
          project_specification={data.Specifications}
          project_status={data.product_delivery_status}
          builder_area={data.vendor_area}
          product_name_get={data.product_name}
          project_img={data.product_main_img}
          // onSpecificationDetailChanged={onSpecificationDetailChanged}
          doc_id_pass={location.search.split("?")[1]}
          project_place={data.city}
          property_category_get={data.property_category}
          product_size={data.product_highlights.product_size}
          //   related_data={relatedinfo}
        />
      ))}
      {/* {<footer/>} */}
    </div>
  );
}

const Frame = ({
  description,
  address,
  top_carosuel_image,
  product_modal_get,
  vendor_details,
  vendor_highlights,
  product_different_color,
  other_details,
  project_specification,
  project_status,
  builder_area,
  product_name_get,
  project_img,
  doc_id_pass,
  project_place,
  property_category_get,
  product_size,
}) => {
  const [img, setImg] = useState(null);
  const [logoimg, setLogoImg] = useState(null);
  const [projectlogoimg, setProjectLogoImg] = useState(null);
  const [multiimg, setMultiImg] = useState([]);
  const [builderProjectName, setBuilderProjectName] = useState("");
  const [builderNameChange, setBuilderNameChange] = useState("");
  const [previewproimg, setPreviewproimg] = useState(false);
  const [productDeliveryStatus, setProductDeliveryStatus] = useState("");
  const [builderDetails, setBuilderDetails] = useState({
    builder_name: "",
    builder_logo: "",
  });
  const [vendorContact, setVendorContact] = useState("");
  const [addressContent, setAddressContent] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [vendorHighlights, setVendorHighlights] = useState({
    Product_No: "",
    currentQuantity: "",
    totalQuantity: "",
    ProductsKg: "",
    productsMg: "",
    Price_range: "",
  });
  const [projectOverview, setProjectOverview] = useState("");
  const [builderAbout, setBuilderAbout] = useState("");
  const [unitDetails, setUnitDetails] = useState([]);
  const [projectSpecification, setProjectSpecification] = useState([]);
  const [logoImgAsFile, setLogoImgAsFile] = useState(null);
  const [projectlogoImgAsFile, setProjectLogoImgAsFile] = useState(null);
  const [projectImgAsFile, setProjectImgAsFile] = useState(null);
  var images = [];
  const [floorImages, setFloorImages] = useState([]);
  const [topCarosuelImagesFiles, setTopCarosuelImagesFiles] = useState([]);
  const [floorImagesFiles, setFloorImagesFiles] = useState([]);

  // const [borchureFile, setBorchureFile] = useState(null);
  const [stateSpec, setStateSpec] = useState([
    {
      title: "",
      value: [""],
    },
  ]);
  const [modalShow, setModalShow] = useState(false);
  const [projectnameshow, setProjectnameshow] = useState("");
  const [place_id, setPlaceId] = useState("");
  const [name_id, setName] = useState("");
  const [modalCheckBox, setCheckBoxalert] = useState(false);

  const [projectNameKeyWord, setProjectKeyWord] = useState("");

  var builder_logo = "";
  var project_logo = "";
  var project_img_logo = "";
  var multiArray = [];
  var multiFloorImagesUrl = [];
  var array_declare = [];

  useEffect(() => {
    date_update();
    // fetch_location();
    setMultiImg(top_carosuel_image);
    setFloorImages(product_different_color);
    setProductDeliveryStatus(project_status);
    setBuilderProjectName(product_name_get);
    setBuilderNameChange(vendor_details.vendor_name);
    setBuilderDetails({
      builder_name: vendor_details.vendor_name,
    });
    setVendorContact(vendor_details.contact_details);
    setAddressContent(vendor_details.Address);
    setVendorHighlights({
      Product_No: vendor_highlights.Product_No,
      currentQuantity: vendor_highlights.current_quantity,
      totalQuantity: vendor_highlights.total_quantity,
      ProductsKg: vendor_highlights.product_in_kg,
      productsMg: vendor_highlights.product_in_mg,
      Price_range: vendor_highlights.Price_range,
    });
    setProjectOverview(description);
    setVendorName(vendor_details.vendor_name);
    setUnitDetails(other_details);
    setProjectSpecification(project_specification);

    if (top_carosuel_image !== undefined) {
      top_carosuel_image.forEach((val) => {
        multiArray.push(val);
      });
    }
    if (project_specification.length !== undefined) {
      setStateSpec(project_specification);
    }
    if (product_size !== undefined && product_size !== "") {
      product_size.forEach((val) => {
        try {
          document.querySelector(
            "[value='" + val.content + "']"
          ).checked = true;
          array_declare.push(val);
        } catch (error) {}
      });
    }
  }, [
    description,
    address,
    top_carosuel_image,
    product_modal_get,
    vendor_details,
    vendor_highlights,
    product_different_color,
    other_details,
    project_specification,
    project_status,
    builder_area,
    product_name_get,
    project_img,
    doc_id_pass,
    project_place,
    property_category_get,
  ]);

  const [loaderShow, setLoaderShow] = useState(false);
  const [date, setDate] = useState(false);
  const [year, setYear] = useState(false);
  const [month, setMonth] = useState(false);
  const [today, setToday] = useState(false);

  function date_update() {
    var year = new Date().getFullYear();
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const d = new Date();
    var month_get = month[d.getMonth()];
    const today = d.getDate();

    setYear(year);
    setDate(today);
    setMonth(month_get);
    setToday(d);
  }

  async function submit_data() {
    setLoaderShow(true);
    try {
      await sizeGet();
    } catch (error) {}
    await checkFunction();
    await handleUpload();
    await handleUpload_multiple();
    await handleUpload_multiple_floor();

    var product_name_get = document.getElementById("input_project_name").value;
    var product_description_get =
      document.getElementById("input_overview").value;
    var project_place_get = document.getElementById("input_place").value;
    var price_range_get = document.getElementById("input_price_range").value;
    var product_no_get = document.getElementById("input_rera").value;
    var product_category_get = document.getElementById(
      "input_property_category"
    ).value;

    const data = {
      vendor_details: {
        vendor_name: localStorage.getItem("vendor_name"),
        Address: localStorage.getItem("vendor_address"),
        contact_details: localStorage.getItem("vendor_mob"),
      },
      city: project_place_get,
      product_highlights: {
        Product_No: product_no_get,
        Price_range: price_range_get,
        product_size: array_declare.length > 0 ? array_declare : "",
      },
      other_details: array_1,
      product_main_img: project_img,
      product_name: product_name_get,
      Specifications: stateSpec,
      top_carosuel_images: multiArray,
      Product_Images: multiFloorImagesUrl,
      product_description: product_description_get,
      date: date,
      year: year,
      month: month,
      timestamp: today,
      property_category: product_category_get,
    };
    // Update a Old document in collection 'Project_Details'

    await db.collection("Product_Details").doc(doc_id_pass).set(data);
    setLoaderShow(false);
    setModalShow(true);
    setTimeout(function refreshPage() {
      window.location.reload(false);
    }, 4000);
  }

  function sizeGet() {
    array_declare = [];
    // try_();
    var checkedValue = null;
    var obj_ameni = {};
    var inputElements = document.getElementsByClassName("form-check-input");
    for (var i = 0; inputElements[i]; ++i) {
      if (inputElements[i].checked) {
        checkedValue = inputElements[i].value;
        obj_ameni = { content: checkedValue };
        array_declare.push(obj_ameni);
      }
    }
  }
  // SEo function start
  var array_declare_seo = [];
  function check_seo_content() {
    console.log("success");
    array_declare_seo = [];
    var checkedValue = null;
    var obj_seo = {};
    var inputElements = document.getElementsByClassName("single-checkbox");
    for (var i = 0; inputElements[i]; ++i) {
      if (inputElements[i].checked) {
        checkedValue = inputElements[i].value;

        obj_seo = { content: checkedValue };
        array_declare_seo.push(obj_seo);
      }
      console.log(array_declare_seo, "seo array");
    }
  }

  // seo section

  function myFunction() {
    var table = document.getElementById("myTable");
    var row = table.insertRow(-1);
    var cell1 = row.insertCell(0);
    var cell2 = row.insertCell(1);
    var cell3 = row.insertCell(2);

    cell1.innerHTML = "<input id='bhk_' type='text' class='form-control'/> ";
    cell2.innerHTML = "<input type='text' class='form-control'/>";
    cell3.innerHTML = "<input type='button' value='-' class='removeRow'/>";
  }
  var array_1 = [];

  const checkFunction = async () => {
    $("#myTable tr").each(function (i, j) {
      if (i !== 0) {
        var tdArr = [];
        var objdata = {};
        $(this)
          .find("td")
          .each(function (p, q) {
            tdArr.push($(q).find("input").val());
          });

        tdArr.forEach((val, index) => {
          if (index === 0) {
            objdata["unit_type"] = val;
          } else if (index === 1) {
            objdata["size"] = val;
          }
        });
        array_1.push(objdata);
      }
    });
  };

  var handleChange = (e) => {
    if (e.target.files[0]) {
      setPreviewproimg(true);
      setProjectImgAsFile(e.target.files[0]);
      let reader = new FileReader();

      reader.onloadend = () => {
        setImg(reader.result);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (previewproimg) {
      var storage = firebase.storage();
      var storageRef = storage.ref();
      var fileUrl = project_img;
      var images = storage.refFromURL(fileUrl);
      var desertRef = storageRef.child(images.fullPath);
      // Delete the file
      desertRef
        .delete()
        .then(() => {
          // File deleted successfully
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
        });
      let file = projectImgAsFile;
      var folder_name = document.getElementById("input_project_name").value;
      var uploadTask = await storageRef
        .child(folder_name + "/" + file.name)
        .put(file)
        .then((response) => response.ref.getDownloadURL());
      project_logo = uploadTask;
      const value = Promise.all(uploadTask).then((res) => {
        return true;
      });
      return value;
    }
  };

  var handleChange_multiple = (e) => {
    let files = e.target.files;
    let ul = [];
    Object.values(files).map((element) => {
      setTopCarosuelImagesFiles((arr) => [...arr, element]);
    });
    for (var i = 0; i < files.length; i++) {
      //for multiple files
      (function (file) {
        var reader = new FileReader();
        reader.onload = function (e) {
          // get file content
          var text = e.target.result;
          // ul.push({ img: text });
          setMultiImg((arr) => [...arr, text]);
        };
        reader.readAsDataURL(file);
      })(files[i]);
    }
  };

  const handleUpload_multiple = async () => {
    multiimg.forEach((val) => {
      if (!val.startsWith("data:")) {
        multiArray.push(val);
      }
    });
    var storage = firebase.storage();
    var storageRef = storage.ref();
    var folder_name = document.getElementById("input_project_name").value;
    const result = topCarosuelImagesFiles.map((each_img) => {
      return storageRef
        .child(folder_name + "/top_carosuel_images/" + each_img.name)
        .put(each_img)
        .then((response) => response.ref.getDownloadURL());
    });
    const value = Promise.all(result).then((res) => {
      res.forEach((val) => {
        multiArray.push(val);
      });
      return true;
    });
    return value;
  };

  // upload multiple floor plan images
  const handleUpload_multiple_floor = async () => {
    floorImages.forEach((val) => {
      if (!val.img.startsWith("data:")) {
        multiFloorImagesUrl.push(val);
      }
    });
    var storage = firebase.storage();
    var storageRef = storage.ref();
    var folder_name = document.getElementById("input_project_name").value;
    setProjectnameshow(folder_name);
    const result = floorImagesFiles.map((each_img) => {
      return storageRef
        .child(folder_name + "/floor_plans/" + each_img.name)
        .put(each_img)
        .then((response) => response.ref.getDownloadURL());
    });
    const value = Promise.all(result).then((res) => {
      // var img = {};
      res.forEach((val) => {
        // img["img"] = val;
        multiFloorImagesUrl.push({ img: val });
      });
      return true;
    });
    return value;
  };
  var handleChange_multiple_floor = (e) => {
    let files = e.target.files;
    Object.values(files).map((element) => {
      setFloorImagesFiles((arr) => [...arr, element]);
    });
    for (var i = 0; i < files.length; i++) {
      //for multiple files
      (function (file) {
        var reader = new FileReader();
        reader.onload = function (e) {
          // get file content
          var text = e.target.result;
          // ul.push({ img: text });
          setFloorImages((arr) => [...arr, { img: text }]);
        };
        reader.readAsDataURL(file);
      })(files[i]);
    }
  };

  const removeTop = async (item) => {
    let array = [...multiimg];
    // let array_get = [...mu]
    const index = array.indexOf(item);
    if (index > -1) {
      array.splice(index, 1); // 2nd parameter means remove one item only
    }
    setMultiImg(array);
  };

  const removeTop2 = async (item, index) => {
    let array = [...floorImages];
    // const index = array.indexOf(item);
    if (index > -1) {
      array.splice(index, 1); // 2nd parameter means remove one item only
    }
    setFloorImages(array);
  };

  $(document).on("click", ".removeRow", function () {
    $(this).closest("tr").remove();
  });

  $(document).on("click", ".removespecRow", function () {
    $(this).closest("tr").remove();
  });

  function handleBHKChange(e, index) {
    // 1. Make a shallow copy of the items
    let items = [...unitDetails];
    // 2. Make a shallow copy of the item you want to mutate
    let item = { ...items[index] };
    // 3. Replace the property you're intested in
    item.bhk = e.target.value;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    items[index] = item;
    // 5. Set the state to our new copy
    setUnitDetails(items);
  }

  function handleUnitTypeChange(e, index) {
    // 1. Make a shallow copy of the items
    let items = [...unitDetails];
    // 2. Make a shallow copy of the item you want to mutate
    let item = { ...items[index] };
    // 3. Replace the property you're intested in
    item.unit_type = e.target.value;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    items[index] = item;
    // 5. Set the state to our new copy
    setUnitDetails(items);
  }

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body class="text-center p-5">
          <img src={successpopup} style={{ width: "65px", height: "65px" }} />
          <h4 class="text-center mt-4">{projectnameshow}</h4>
          <p class="text-center">Your product was updated successfully</p>
          <Button onClick={props.onHide} className="btn-popup-sucess mt-3">
            Continue
          </Button>
        </Modal.Body>
      </Modal>
    );
  }

  function addPointValue(index) {
    const row = [...stateSpec];
    row[index].value.push("");
    setStateSpec(row);
  }

  function addSpeRow() {
    const row = [...stateSpec];
    const rowsInput = {
      title: "",
      value: [""],
    };
    row.push(rowsInput);
    setStateSpec(row);
  }

  function deleteSpeRow(index) {
    const row = [...stateSpec];
    row.splice(index, 1);
    setStateSpec(row);
  }

  function deleteSpeCntRow(index, index1) {
    const row = [...stateSpec];
    row[index].value.splice(index1, 1);
    setStateSpec(row);
  }

  $(".single-checkbox").on("change", function (e) {
    if ($(".single-checkbox:checked").length > 2) {
      $(this).prop("checked", false);
      setCheckBoxalert(true);
    }
  });

  // end
  return (
    <div>
      <div class="container mt-5 mb-4">
        <div class="row">
          <div class="col-md-12">
            <h4>Edit Products</h4>
          </div>
        </div>
      </div>
      <div className="container pb-5">
        <div class="card mt-3 mb-5">
          <div class="card-header">
            <h5 class="m-0">Product slider images</h5>
          </div>
          <div class="card-body mb-4">
            <div class="row">
              <div class="form-group col-md-6">
                <div class="upload-relative">
                  <div>
                    <label class="form-label" for="customFile">
                      Add more images (Must three images add)
                    </label>
                    <input
                      type="file"
                      class="form-control"
                      onChange={handleChange_multiple}
                      multiple
                    />
                  </div>
                  {multiimg.length !== 0 ? (
                    <p class="m-0 upload-absolute">{multiimg.length} files</p>
                  ) : (
                    <p class="m-0 upload-absolute">No files choosen</p>
                  )}
                </div>
              </div>
              <div class="col-md-12">
                <div class="d-flex mt-2">
                  {multiimg !== undefined &&
                    multiimg.map((val) => (
                      <div class="preview-img-relative">
                        <img src={val} class="preview-img" />
                        <div class="preview-img-absolute">
                          <button
                            onClick={() => removeTop(val)}
                            class="btn-remove"
                          >
                            x
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-5">
          <div class="card-header">
            <h5 class="m-0">Product details</h5>
          </div>
          <div class="card-body mb-4">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="inputEmail4">Product Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="input_project_name"
                  value={builderProjectName}
                  onChange={(e) => setBuilderProjectName(e.target.value)}
                />
              </div>
              {/* <div class="form-group col-md-6">
                <label for="inputPassword4">Product Delivery stage</label>
                <select id="input_status" class="form-control">
                  <option selected>{project_status}</option>
                  <option>Paid</option>
                  <option>Free Delivery</option>
                </select>
              </div> */}
              <div class="form-group col-md-6">
                <label for="inputEmail4">Product Main image</label>
                <input
                  type="file"
                  class="form-control"
                  id="input_project_img"
                  onChange={handleChange}
                />
                <div>
                  <img
                    class="preview-img mt-3"
                    src={previewproimg ? img : project_img}
                  />
                </div>
                {/* <button onClick={handleUpload}>click</button> */}
              </div>
              <div class="form-group col-md-6">
                <label for="inputPassword4">Product Category</label>
                <select id="input_property_category" class="form-control">
                  <option selected>{property_category_get}</option>
                  <option value="Fashion">Fashion</option>
                  <option value="Electronics">Electronics</option>
                  <option value="Food & Drinks">Food & Drinks</option>
                  <option value="Beauty and health care">
                    Beauty and health care
                  </option>
                  <option value="Domestic Appliances">
                    Domestic Appliances
                  </option>
                  <option value="Sports and gym">Sports and gym</option>
                  <option value="Watches & Jewellery">
                    Watches & Jewellery
                  </option>
                  <option value="Lifestyle & outfits">
                    Lifestyle & outfits
                  </option>
                  <option value="Stationaries">Stationaries</option>
                  <option value="Ship orders">Ship orders</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-5">
          <div class="card-header">
            <h5 class="m-0">Product Configuration</h5>
          </div>
          <div class="card-body mb-4">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="inputPassword4">Product No</label>
                <input
                  type="text"
                  class="form-control"
                  id="input_rera"
                  value={vendorHighlights.Product_No}
                  onChange={(e) =>
                    setVendorHighlights({ Product_No: e.target.value })
                  }
                />
              </div>

              <div class="form-group col-md-6">
                <label for="inputPassword4">Price Range</label>
                <input
                  type="text"
                  class="form-control"
                  id="input_price_range"
                  value={vendorHighlights.Price_range}
                  onChange={(e) =>
                    setVendorHighlights({ Price_range: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div class="card-body mb-4 mt-3">
            {property_category_get === "Fashion" ? (
              <>
                <label for="inputPassword4">Product Sizes</label>
                <div class="d-flex">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox0"
                      value="S"
                      // onClick={() => check_box_alert()}
                    />
                    <h6 class="text-center">
                      <label class="form-check-label p-0" for="inlineCheckbox1">
                        S
                      </label>
                    </h6>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox0"
                      value="M"
                      // onClick={() => check_box_alert()}
                    />
                    <h6 class="text-center">
                      <label class="form-check-label p-0" for="inlineCheckbox1">
                        M
                      </label>
                    </h6>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox0"
                      value="L"
                      // onClick={() => check_box_alert()}
                    />
                    <h6 class="text-center">
                      <label class="form-check-label p-0" for="inlineCheckbox1">
                        L
                      </label>
                    </h6>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox0"
                      value="XL"
                      // onClick={() => check_box_alert()}
                    />
                    <h6 class="text-center">
                      <label class="form-check-label p-0" for="inlineCheckbox1">
                        XL
                      </label>
                    </h6>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox0"
                      value="XXL"
                      // onClick={() => check_box_alert()}
                    />
                    <h6 class="text-center">
                      <label class="form-check-label p-0" for="inlineCheckbox1">
                        XXL
                      </label>
                    </h6>
                  </div>
                </div>
              </>
            ) : property_category_get === "Lifestyle & outfits" ? (
              <>
                <label for="inputPassword4">Product Sizes</label>
                <div class="d-flex">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox0"
                      value="6"
                      // onClick={() => check_box_alert()}
                    />
                    <h6 class="text-center">
                      <label class="form-check-label p-0" for="inlineCheckbox1">
                        6
                      </label>
                    </h6>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox0"
                      value="7"
                      // onClick={() => check_box_alert()}
                    />
                    <h6 class="text-center">
                      <label class="form-check-label p-0" for="inlineCheckbox1">
                        7
                      </label>
                    </h6>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox0"
                      value="8"
                      // onClick={() => check_box_alert()}
                    />
                    <h6 class="text-center">
                      <label class="form-check-label p-0" for="inlineCheckbox1">
                        8
                      </label>
                    </h6>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox0"
                      value="9"
                      // onClick={() => check_box_alert()}
                    />
                    <h6 class="text-center">
                      <label class="form-check-label p-0" for="inlineCheckbox1">
                        9
                      </label>
                    </h6>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox0"
                      value="10"
                      // onClick={() => check_box_alert()}
                    />
                    <h6 class="text-center">
                      <label class="form-check-label p-0" for="inlineCheckbox1">
                        10
                      </label>
                    </h6>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>

        <div class="card mb-5">
          <div class="card-header">
            <h5 class="m-0">Product Description</h5>
          </div>
          <div class="card-body mb-4">
            <div class="row">
              <div class="form-group col-md-12">
                <label class="form-label" for="customFile">
                  Product Description
                </label>
                <textarea
                  type="text"
                  class="form-control h-200"
                  id="input_overview"
                  value={projectOverview}
                  onChange={(e) => setProjectOverview(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-5">
          <div class="card-header">
            <h5 class="m-0">Product Specification</h5>
          </div>
          <div class="card-body mb-4">
            <div class="row">
              <div id="unit_section">
                <div>
                  <table class="table" id="myTable" data="regMultipleTable">
                    <tr>
                      <th id="type">Key</th>
                      <th id="bhk">Value</th>
                      {/* <th id="build_up_area">Build up area</th> */}
                    </tr>
                    {unitDetails !== undefined &&
                      unitDetails.map((row, idx) => (
                        <tr>
                          <td>
                            <input
                              id="bhk_"
                              type="text"
                              class="form-control"
                              value={row.unit_type}
                              onChange={(e) => handleBHKChange(e, idx)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              class="form-control"
                              value={row.size}
                              onChange={(e) => handleUnitTypeChange(e, idx)}
                            />
                          </td>

                          <td>
                            <input type="button" value="-" class="removeRow" />
                          </td>
                        </tr>
                      ))}
                  </table>
                  <button
                    class="btn-red mt-3 border-full btn-add-row"
                    type="button"
                    onClick={myFunction}
                  >
                    <img
                      src={plus}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginTop: "2px",
                      }}
                    />
                    <span style={{ marginLeft: "10px" }}> Add more data </span>
                  </button>

                  {/* <div class="col">
            <input type="text" class="form-control" value="Build up area" disabled/>
          </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-5">
          <div class="card-header">
            <h5 class="m-0">Same Product Different Color</h5>
          </div>
          <div class="card-body mb-4">
            <div class="row">
              <div class="form-group col-md-6">
                <div class="upload-relative">
                  <div>
                    <label class="form-label" for="customFile">
                      Add multiple images
                    </label>
                    <input
                      type="file"
                      class="form-control"
                      onChange={handleChange_multiple_floor}
                      multiple
                    />
                  </div>
                  {floorImages.length !== 0 ? (
                    <p class="m-0 upload-absolute">
                      {floorImages.length} files
                    </p>
                  ) : (
                    <p class="m-0 upload-absolute">No files choosen</p>
                  )}
                </div>
              </div>
              <div class="col-md-12">
                <div class="d-flex mt-2">
                  {floorImages !== undefined &&
                    floorImages.map((val, index) => (
                      <div class="preview-img-relative">
                        <img src={val.img} class="preview-img" />
                        <div class="preview-img-absolute">
                          <button
                            onClick={() => removeTop2(val.img, index)}
                            class="btn-remove"
                          >
                            x
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 
        <div class="card mb-5">
          <div class="card-header">
            <h5 class="m-0">Product Specification</h5>
            <p className="mb-0 mt-2 text-grey">
              Note : Enter Project specification points are separated by comma
            </p>
          </div>
          <div class="card-body mb-4">
            <div class="row">
              <div id="unit_section">
                <div>
                  {stateSpec.length > 0 ? (
                    <table
                      class="table"
                      id="specTable2"
                      data="regMultipleTable"
                    >
                      <tr>
                        <th id="spec_title2">Specification Title</th>
                        <th id="spec_points2">Specification Points</th>
                        <th class="add-delete-table-title">Add points</th>
                        <th class="add-delete-table-title">Delete row</th>
                      </tr>

                      {stateSpec.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <input
                                class="form-control"
                                style={{ margin: 10 }}
                                type="text"
                                value={item.title}
                                onChange={(e) => {
                                  let array = [...stateSpec];
                                  array[index].title = e.target.value;
                                  setStateSpec(array);
                                }}
                              />
                            </td>
                            <td>
                              <table class="spec-points-table">
                                {item.value.map((item1, index1) => {
                                  return (
                                    <tr>
                                      <td>
                                        <textarea
                                          class="form-control"
                                          type="text"
                                          value={item1}
                                          onChange={(e) => {
                                            let array = [...stateSpec];
                                            array[index].value[index1] =
                                              e.target.value;
                                            setStateSpec(array);
                                          }}
                                        ></textarea>
                                      </td>
                                      <td>
                                        <button
                                          class="remove-btn-small"
                                          onClick={() =>
                                            deleteSpeCntRow(index, index1)
                                          }
                                        >
                                          -
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </table>
                            </td>
                            <td class="text-center">
                              <button
                                class="add-btn-small"
                                onClick={() => addPointValue(index)}
                              >
                                +
                              </button>
                            </td>
                            <td class="text-center">
                              <button
                                class="remove-btn-small"
                                onClick={() => deleteSpeRow(index)}
                              >
                                -
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  ) : (
                    <table
                      class="table"
                      id="specTable2"
                      data="regMultipleTable"
                    >
                      <tr>
                        <th id="spec_title2">Specification Title</th>
                        <th id="spec_points2">Specification Points</th>
                        <th>Add</th>
                        <th>Delete</th>
                      </tr>

                      {stateSpec.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <input
                                class="form-control"
                                style={{ margin: 10 }}
                                type="text"
                                value={item.title}
                                onChange={(e) => {
                                  let array = [...stateSpec];
                                  array[index].title = e.target.value;
                                  setStateSpec(array);
                                }}
                              />
                            </td>
                            <td>
                              <table>
                                {item.value.map((item1, index1) => {
                                  return (
                                    <tr>
                                      <td>
                                        <textarea
                                          class="form-control"
                                          type="text"
                                          value={item1}
                                          onChange={(e) => {
                                            let array = [...stateSpec];
                                            array[index].value[index1] =
                                              e.target.value;
                                            setStateSpec(array);
                                          }}
                                        ></textarea>
                                      </td>
                                      <td>
                                        <button
                                          onClick={() =>
                                            deleteSpeCntRow(index, index1)
                                          }
                                        >
                                          -
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </table>
                            </td>
                            <td>
                              <button onClick={() => addPointValue(index)}>
                                +
                              </button>
                            </td>
                            <td>
                              <button onClick={() => deleteSpeRow(index)}>
                                -
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  )}
                  <button
                    class="btn-red mt-3 border-full btn-add-row"
                    type="button"
                    onClick={() => addSpeRow()}
                  >
                    <img
                      src={plus}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginTop: "4px",
                      }}
                    />
                    <span style={{ marginLeft: "10px" }}> Add more data </span>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div class="card mb-4">
          <div class="card-header">
            <h5 class="m-0">Delivery details</h5>
          </div>
          <div class="card-body mb-4">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="inputState">Location</label>
                <select id="input_place" class="form-control">
                  <option selected>{project_place}</option>
                  <option>Chennai-Ennore</option>
                  <option>Tutucorin</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />

        <div class="d-flex">
          <div>
            <button
              onClick={() => submit_data()}
              type="submit"
              class="btn-red mt-3 border-full"
            >
              Update
            </button>
          </div>
          <div style={{ marginLeft: "32px" }}>
            {loaderShow === true ? (
              <div className={"item"}>
                <Loader
                  type="spinner-cub"
                  bgColor={"red"}
                  title={"please wait"}
                  color={"red"}
                  size={30}
                />
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
