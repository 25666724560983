// import section
// Navbar section of the project
import { useNavigate, Outlet, Link, useLocation } from "react-router-dom";
import jQuery from "jquery";
import logo from "./images/logo.jpeg";
import firebase from "firebase";
import "./css/navbar.scss";
import "./css/update-style.css";

// drop down button function start
(function ($) {
  // Begin jQuery
  $(function () {
    // DOM ready
    // If a link has a dropdown, add sub menu toggle.
    $("nav ul li a:not(:only-child)").click(function (e) {
      $(this).siblings(".nav-dropdown").toggle();
      // Close one dropdown when selecting another
      $(".nav-dropdown").not($(this).siblings()).hide();
      e.stopPropagation();
    });
    // Clicking away from dropdown will remove the dropdown class
    $("html").click(function () {
      $(".nav-dropdown").hide();
    });
    // Toggle open and close nav styles on click
    $("#nav-toggle").click(function () {
      $("nav ul").slideToggle();
    });
    // Hamburger to X toggle
    $("#nav-toggle").on("click", function () {
      this.classList.toggle("active");
    });
  }); // end DOM ready
})(jQuery); // end jQuery

// Close the dropdown if the user clicks outside of it
window.onclick = function (event) {
  if (!event.target.matches(".dropbtn")) {
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains("show")) {
        openDropdown.classList.remove("show");
      }
    }
  }
};
// drop down button function end

// Main function start
const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  function logout() {
    firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
        localStorage.removeItem("login");
        navigate("/login");
      })
      .catch(function (error) {
        // An error happened.
      });
  }

  function Seo_Category_Click(pass_data) {
    let category_ = pass_data;
    let data1 = pass_data.trim().split(" ");
    var trim_data = data1.join("-");
    navigate(`/seo-category/:${trim_data}`, { state: { category: category_ } });
  }
  if (location.pathname === "/login" || location.pathname === "/sign-up") {
    return null;
  } else {
    return (
      <>
        <section class="navigation">
          <div class="container">
            <div class="brand">
              <Link to="/dashboard" style={{ textDecoration: "none" }}>
                <a href="#!">
                  <img src={logo} width="50px" height="auto" />
                </a>
              </Link>
            </div>
            <nav>
              <div class="nav-mobile">
                <a id="nav-toggle" href="#!">
                  <span></span>
                </a>
              </div>
              <ul class="nav-list">
                <Link to="/dashboard" style={{ textDecoration: "none" }}>
                  <li>
                    <a style={{ height: "58px" }} href="#!">
                      Dashboard
                    </a>
                  </li>
                </Link>
                <Link to="/add-products">
                  <li>
                    <a style={{ height: "58px" }} href="#!">
                      Add Products
                    </a>
                  </li>
                </Link>
                <Link to="/products">
                  <li>
                    <a style={{ height: "58px" }} href="#!">
                      Products
                    </a>
                  </li>
                </Link>

                {/* <Link to="/customer-feedback">
                  <li>
                    <a style={{ height: "58px" }} href="#!">
                      Customer Feedback
                    </a>
                  </li>
                </Link> */}
                <Link to="/order-details">
                  <li>
                    <a style={{ height: "58px" }} href="#!">
                      Order Details
                    </a>
                  </li>
                </Link>
                <Link to="/account">
                  <li>
                    <a style={{ height: "58px" }} href="#!">
                      Account
                    </a>
                  </li>
                </Link>

                <li>
                  <button onClick={() => logout()} class="logout-btn">
                    <a style={{ height: "58px" }}>Logout</a>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </section>

        <Outlet />
      </>
    );
  }
};
// Main function end
export default Navbar;
